import react, { useState, useEffect, useCallback, useRef } from "react";

import { useLocation, useParams, NavLink } from "react-router-dom";

import { GetStoreByIdAPI, GetCouponsByStore } from "../../api/landing/StoreAPI";
import { GetAllTypesAPI } from "../../api/landing/CategoryAPI";

import {
  Box,
  HStack,
  Text,
  Center,
  Spinner,
  Card,
  Button,
  Heading,
  CardBody,
  Stack,
  Grid,
  GridItem,
  Image,
  InputRightElement,
  InputLeftElement,
  Input,
  InputGroup,
  TabPanel,
  TabPanels,
  TabList,
  Tabs,
  Tab,
  useDisclosure,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { MainBox } from "../../components/landing/mainBox/MainBox";
import { ModalAlert } from "../../components/landing/modalAlert/ModalAlert";
import { ModalAlert2 } from "../../components/landing//modalAlert/ModalAlert2";

import { CouponCard } from "../../components/landing/couponCard/CouponCard";
import checked from "../../assets/images/success.png";
import logo from "../../assets/images/logo.png";

export const Store = () => {
  const { i18n, t } = useTranslation();
  const baseUrl = "/" + i18n.language;
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState(`${t("Please try again.")}`);
  const [generalData, setGeneralData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [couponsData, setCouponsData] = useState([]);
  const [types, setTypes] = useState([]);
  const [typesObj, setTypesObj] = useState({});
  const [isLoading2, setIsLoading2] = useState(true);
  const [storeUrlCopied, setStoreUrlCopied] = useState("");
  const [couponID, setCouponID] = useState("");
  const [storeID, setStoreID] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const {
    isOpen: isCopiedOpen,
    onOpen: onCopiedOpen,
    onClose: onCopiedClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const loadTableData = useCallback((changeID) => {
    document.getElementById("main_content").scrollTo(0, 0);
    let pageID;
    if (changeID) {
      pageID = changeID;
    } else {
      pageID = id;
    }

    GetStoreByIdAPI(pageID)
      .then((res) => {
        setGeneralData(res.store);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        onErrorOpen();
        setErrorMessage(err?.response?.data?.message || t("Please try again."));
        // console.log(err);
      });

    GetCouponsByStore(pageID)
      .then((res) => {
        setCouponsData(res.couponMap);
        setIsLoading2(false);
        //return res;
      })
      .catch((err) => {
        // console.log(err);
        onErrorOpen();
      });
    GetAllTypesAPI()
      .then((res) => {
        setTypesObj(res.types);
        let keysArr = Object.keys(res.types);
        setTypes(keysArr);
        // setIsLoading2(false);
        //return res;
      })
      .catch((err) => {
        // console.log(err);
        onErrorOpen();
        setErrorMessage(err?.response?.data?.message || t("Please try again."));
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  useEffect(() => {
    let changeID = id;
    setIsLoading(true);
    loadTableData(changeID);
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Store</title>
        <meta
          name="description"
          content={`${
            i18n.language == "en"
              ? generalData.store_seo_en
              : generalData.store_seo_ar
          }`}
        />
      </Helmet>
      {isLoading ? (
        <>
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList width="100%">
              <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                minHeight="340px"
                background="white"
                marginBottom="30px"
                className="coupon_card"
                padding="40px 60px"
                borderRadius="25px"
                width="100%"
              >
                {i18n.language == "en" ? (
                  <Image
                    objectFit="cover"
                    width="300px"
                    src={
                      generalData.image
                        ? `https://www.glocoupon.com/api2/${generalData.image}`
                        : "https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg"
                    }
                    alt="brand"
                    mr={16}
                    borderRadius="25px"
                  />
                ) : (
                  <Image
                    objectFit="cover"
                    width="300px"
                    src={
                      generalData.image
                        ? `https://www.glocoupon.com/api2/${generalData.image}`
                        : "https://mtek3d.com/wp-content/uploads/2018/01/image-placeholder-500x500.jpg"
                    }
                    alt="brand"
                    ml={16}
                    borderRadius="25px"
                  />
                )}

                <Stack flex="1">
                  <CardBody className="coupon_card-body" alignItems="center">
                    <Heading size="md" color="#022E5B" fontSize="30px">
                      {i18n.language == "en"
                        ? generalData.name_en
                        : generalData.name_ar}
                    </Heading>

                    <Text py="2" color="#022E5B" fontSize="22px">
                      {i18n.language == "en"
                        ? generalData.description_en
                        : generalData.description_ar}
                    </Text>

                    <Box display="flex" marginTop={6}>
                      <Tab className="tab_custom-btn">{t("All")}</Tab>
                      <Tab className="tab_custom-btn">{t("Deals")}</Tab>
                    </Box>
                  </CardBody>
                </Stack>
              </Card>
            </TabList>
            <Grid
              templateColumns="repeat(8, 1fr)"
              gap={4}
              paddingBottom={4}
              className="brands_container"
            >
              <GridItem
                rowSpan={1}
                colSpan={2}
                background="white"
                padding="15px 30px"
                borderRadius="25px"
                className="brands_container-sm"
              >
                <Box background="white">
                  {types.map((el) => {
                    let renderList = () => {
                      return typesObj[`${el}`].stores.map((item) => {
                        return (
                          <NavLink
                            to={`${baseUrl}/store/${item.id}`}
                            color="#022E5B"
                            fontSize="18px"
                            marginBottom={4}
                            onClick={() =>
                              window.dataLayer.push({
                                event: "filter-stores-cupouns-buttons",
                              })
                            }
                          >
                            <Text color="#022E5B" fontSize="18px">
                              {i18n.language == "en"
                                ? item.name_en
                                : item.name_ar}
                            </Text>
                          </NavLink>
                        );
                      });
                    };

                    return (
                      <>
                        <Text
                          pt="2"
                          color="#022E5B"
                          fontSize="23px"
                          fontWeight="bold"
                        >
                          {i18n.language == "en"
                            ? typesObj[`${el}`].type_en
                            : typesObj[`${el}`].type_ar}
                        </Text>
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginTop={4}
                        >
                          {renderList()}
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </GridItem>
              <GridItem colSpan={6} className="brands_container-lg">
                <TabPanels paddingTop={0}>
                  <TabPanel paddingTop={0}>
                    {couponsData.length > 0 ? (
                      <>
                        {couponsData.map((el) => {
                          return (
                            <>
                              <CouponCard
                                path={`${baseUrl}/coupon/${el.id}`}
                                secondUrl={`${baseUrl}/store/${el.id}`}
                                onCopiedOpen={onCopiedOpen}
                                storeUrl={el.store_url}
                                code={el?.code}
                                setStoreUrlCopied={setStoreUrlCopied}
                                icon={`https://www.glocoupon.com/api2/${el.store_image}`}
                                title={`${
                                  i18n.language == "en"
                                    ? el.name_en
                                    : el.name_ar
                                }`}
                                storeName={`${
                                  i18n.language == "en"
                                    ? el.store_name_en
                                    : el.store_name_ar
                                }`}
                                desc={`${
                                  i18n.language == "en"
                                    ? el.store_desc_en
                                    : el.store_desc_ar
                                }`}
                                couponDesc={`${
                                  i18n.language == "en"
                                    ? el.coupon_desc_en
                                    : el.coupon_desc_ar
                                }`}
                                setCouponID={setCouponID}
                                id={el.id}
                                storeID={el.store_id}
                                setStoreID={setStoreID}
                                setCouponCode={setCouponCode}
                              />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Box
                        textAlign="center"
                        paddingY={14}
                        borderRadius="20px"
                        background="white"
                        fontSize="24px"
                        width="100%"
                      >
                        {t("There is no coupons to show")}
                      </Box>
                    )}
                    {i18n.language == "en" &&
                      `${generalData.store_seo_en}`.trim() !== "" && (
                        <Box
                          paddingY={8}
                          paddingX={10}
                          minHeight={100}
                          borderRadius="20px"
                          background="white"
                          fontSize="18px"
                          marginTop={9}
                        >
                          <Text fontWeight="bold">{t("seoDesc")}:</Text>
                          {generalData.store_seo_en}
                        </Box>
                      )}
                    {i18n.language != "en" &&
                      `${generalData.store_seo_ar}`.trim() !== "" && (
                        <Box
                          paddingY={8}
                          paddingX={10}
                          minHeight={100}
                          borderRadius="20px"
                          background="white"
                          fontSize="18px"
                          marginTop={9}
                        >
                          <Text fontWeight="bold">{t("seoDesc")}:</Text>
                          {generalData.store_seo_ar}
                        </Box>
                      )}
                  </TabPanel>

                  <TabPanel paddingTop={0}>
                    {couponsData.length > 0 ? (
                      <>
                        {couponsData.map((el) => {
                          return (
                            <>
                              <CouponCard
                                path={`${baseUrl}/coupon/${el.id}`}
                                secondUrl={`store/${el.id}`}
                                onCopiedOpen={onCopiedOpen}
                                storeUrl={el.store_url}
                                code={el?.code}
                                setStoreUrlCopied={setStoreUrlCopied}
                                icon={`https://www.glocoupon.com/api2/${el.store_image}`}
                                title={`${
                                  i18n.language == "en"
                                    ? el.name_en
                                    : el.name_ar
                                }`}
                                storeName={`${
                                  i18n.language == "en"
                                    ? el.store_name_en
                                    : el.store_name_ar
                                }`}
                                desc={`${
                                  i18n.language == "en"
                                    ? el.store_desc_en
                                    : el.store_desc_ar
                                }`}
                                couponDesc={`${
                                  i18n.language == "en"
                                    ? el.coupon_desc_en
                                    : el.coupon_desc_ar
                                }`}
                                setCouponID={setCouponID}
                                id={el.id}
                                storeID={el.store_id}
                                setStoreID={setStoreID}
                                setCouponCode={setCouponCode}
                              />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Box
                        textAlign="center"
                        paddingY={14}
                        borderRadius="20px"
                        background="white"
                        fontSize="24px"
                        width="100%"
                      >
                        {t("There is no coupons to show")}
                      </Box>
                    )}
                  </TabPanel>
                </TabPanels>
              </GridItem>
            </Grid>
          </Tabs>

          {/* <Box
            background="white"
            position="absolute"
            left="0"
            right="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            paddingX="55px"
            paddingBottom={16}
            className="section_bef-footer "
          >
            <Image src={logo} alt="logo" width="200px" paddingY={12} />

            <Box
              marginTop={6}
              borderRadius="25px"
              background="#022F59"
              display="flex"
              padding="20px"
              width="100%"
            >
              <Box width="50%">
                <Text color="white" fontSize="25px">
                  {t("contact1")}
                </Text>
                <Text color="#6AEFAB" fontSize="25px">
                  {t("contact2")}
                </Text>
              </Box>
              <Box width="50%">
                <InputGroup
                  size="md"
                  height="100%"
                  background="white"
                  borderRadius="10px"
                >
                  <Input
                    pr="4.5rem"
                    placeholder=""
                    height="100%"
                    borderTopRightRadius="10px"
                    borderBottomRightRadius="10px"
                  />
                  <InputRightElement
                    width="4.5rem"
                    background="#6AEFAB"
                    borderTopRightRadius="10px"
                    borderBottomRightRadius="10px"
                    height="100%"
                    paddingX={16}
                    position="relative"
                  >
                    <Button
                      // h="1.75rem"
                      size="sm"
                      background="#6AEFAB" onClick={handleClick} 
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      height="100%"
                    >
                      <Text>{t("Subscribe")} </Text>
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Box>
          </Box> */}
        </>
      )}

      {storeUrlCopied && (
        <ModalAlert
          isOpen={isCopiedOpen}
          onClose={onCopiedClose}
          title={t("Code Copied")}
          desc={t("Paste This Coupon Code Checkout")}
          linkText={t("Go to the store")}
          btnWidthFull={true}
          icon={checked}
          disableClose={true}
          path={`${baseUrl}/Store/${storeID}`}
          hasDesc={true}
          closeOnOverlayClicked={true}
          setStoreUrlCopied={setStoreUrlCopied}
          couponID={couponID}
          couponCode={couponCode}
          storeUrlCopied={storeUrlCopied}
        />
      )}
      <ModalAlert2
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={t("Error")}
        desc={errorMessage}
        btnWidthFull={true}
        icon={checked}
        path={`${baseUrl}/Store/${storeID}`}
        hasDesc={true}
        isError={true}
      />
    </div>
  );
};
