import React, { useState, useEffect } from "react";

import {
  GetBannerByIdAPI,
  UpdateBannerAPI,
  AddBannerAPI,
} from "../../api/BannerAPI";
import { GetCouponsFullAPI } from "../../api/CouponAPI";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../utils/General";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { BannerForm } from "./BannerForm";
import { validationBannerForm } from "./validationBannerForm";

import checked from "../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const Banner = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdownObj, setDropdownObj] = useState([]);

  let submitModalTitle = id
    ? "Banner is successfully updated."
    : "Banner is successfully created.";

  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        coupon_id: formikValue.coupon_id,
      };

      UpdateBannerAPI(submitValues)
        .then((res) => {
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        coupon_id: formikValue.coupon_id,
      };
      AddBannerAPI(submitValues)
        .then((res) => {
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  const getDropdowns = () => {
    let getCoupons = new Promise((resolve, reject) => {
      GetCouponsFullAPI(1)
        .then((res) => {
          if (res.couponsMap.length > 0) {
            let arr = [];
            res.couponsMap.forEach((item) => {
              arr.push({
                value: item.id,
                label: item.name_en,
              });
            });

            resolve(arr);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("Something went wrong");
        });
    });

    return Promise.all([getCoupons]).then((values) => {
      setDropdownObj({
        copuons: values[0],
      });
      return {
        copuons: values[0],
      };
    });
  };

  useEffect(() => {
    getDropdowns().then((data) => {
      if (!!id) {
        setErrorMessage("");
        GetBannerByIdAPI(id)
          .then((res) => {
            setInitFormVal({
              coupon_id: res.subcategories.coupon_id,
            });
            setGeneralData(res.subcategories);
          })
          .catch((err) => {
            setErrorMessage("Something went wrong");
            onErrorOpen();
          });
      } else {
        setInitFormVal({
          coupon_id: "",
        });
      }
    });
  }, []);

  return (
    <div className="Assumptions">
      <>
        <MainBox px={0}>
          {id === undefined ? (
            <MainTitle title="Create Banner" bBottom={true} px={7} />
          ) : (
            <MainTitle title="Edit Banner" bBottom={true} px={7} />
          )}
          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationBannerForm}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <BannerForm
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    dropdownObj={dropdownObj}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/Admin/BannersHistory`}
        />
      </>
    </div>
  );
};
