import React, { useState, useEffect } from "react";

import {
  GetTypesByIdAPI,
  UpdateTypesAPI,
  AddTypesAPI,
} from "../../api/HomeAPI";
import { GetCouponsFullAPI } from "../../api/CouponAPI";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../utils/General";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { TypesForm } from "./TypesForm";
import { validationTypesForm } from "./validationTypesForm";

import checked from "../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const Types = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [dropdownObj, setDropdownObj] = useState([]);

  let submitModalTitle = id
    ? "Types is successfully updated."
    : "Types is successfully created.";

  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        id: id,
        type_en: formikValue.type_en,
        type_ar: formikValue.type_ar,
      };

      UpdateTypesAPI(submitValues)
        .then((res) => {
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        name_en: formikValue.type_en,
        name_ar: formikValue.type_ar,
      };
      AddTypesAPI(submitValues)
        .then((res) => {
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    if (!!id) {
      setErrorMessage("");
      GetTypesByIdAPI(id)
        .then((res) => {
          setInitFormVal({
            type_en: res.type.type_en,
            type_ar: res.type.type_ar,
          });
          setGeneralData(res.type);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else {
      setInitFormVal({
        type_en: "",
        type_ar: "",
      });
    }
  }, []);

  return (
    <div className="Types">
      <>
        <MainBox px={0}>
          {id === undefined ? (
            <MainTitle title="Create Types" bBottom={true} px={7} />
          ) : (
            <MainTitle title="Edit Types" bBottom={true} px={7} />
          )}
          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationTypesForm}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <TypesForm
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    dropdownObj={dropdownObj}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/Admin/TypesHistory`}
        />
      </>
    </div>
  );
};
