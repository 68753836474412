import React, { useState } from "react";

import {
  Box,
  Divider,
  Flex,
  RadioGroup,
  Radio,
  SimpleGrid,
  GridItem,
  Text,
  Stack,
  Image,
  Input,
  Center,
  Spinner,
  Button,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { TextArea } from "../../components/textArea/TextArea";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import Cookies from "js-cookie";

export const CategoryForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  formikValues,
  dirty,
  isValid,
  setDisableSave,
  disableSave,
  dismissSave,
  setDismissSave,
  handleOnSubmit,
  onUploadOpen,
  generalData,
  onUploadClose,
  isUploadOpen,
  setErrorMessage,
  setImageObj,
  onErrorOpen,
}) => {
  return (
    <>
      <Box
        maxW={["100%", "100%", "70%"]}
        width={["100%", "100%", "70%"]}
        alignSelf="flex-start"
      >
        <Text
          className="title_sm"
          display="flex"
          mb={2}
          mt={0}
          component="div"
          textTransform="uppercase"
          fontSize="12px"
          letterSpacing="0.04em"
        >
          Image
        </Text>
        {!!generalData?.image && (
          <Image
            width="150px"
            objectFit="cover"
            height="150px"
            src={`https://www.glocoupon.com/api2/${generalData.image}`}
          />
        )}
        <Input
          paddingTop="5px"
          marginBottom="15px"
          size="md"
          type="file"
          name="image"
          errors={errors}
          touched={touched}
          values={formikValues.image}
          accept="image/*"
          onChange={(event) => {
            setFieldValue("image", event.currentTarget.files[0]);
          }}
        />
        <InputFieldValidation
          label="name_en"
          inputType="text"
          inputId="name_en"
          inputName="name_en"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
        <InputFieldValidation
          label="name_ar"
          inputType="text"
          inputId="name_ar"
          inputName="name_ar"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
      </Box>
      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
      ></Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          <MainButton
            title="Cancel"
            btnStyle="transparent"
            fontColor="light"
            navLink={true}
            path={`/Admin/CategoriesHistory`}
          />

          <MainButton
            title="Submit"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
