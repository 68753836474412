export default {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "Dashboard Home": "Dashboard Home",
      Stores: "Stores",
      Categories: "Categories",
      "Glo Coupon online shopping offers": "Glo Coupon online shopping offers",
      "Popular Stores": "Popular Stores",
      "Today's Best Coupons": "Today's Best Coupons",
      "Show Coupon": "Show Coupon",
      "View all": "View all",
      Offers: "Offers",
      contact1: "Lorem lorem lorem",
      contact2: "Lorem lorem lorem",
      "Contact Us": "Contact Us",
      "Who Are We": "Who Are We",
      "Follow Us": "Follow Us",
      Subscribe: "Subscribe",
      "All Categories": "All Categories",
      "All Stores": "All Stores",
      All: "All",
      "Coupon code": "Coupon code",
      Deals: "Deals",
      "Related Categories": "Related Categories",
      "Popular Stores": "Popular Stores",
      "Top Stores": "Top Stores",
      "Activate Deal": "Activate Deal",
      "Code Copied": "Code Copied",
      Code: "Code",
      "Paste This Coupon Code Checkout": "Paste This Coupon Code Checkout",
      "Show Details": "Show Details",
      "Search about:": "Search about:",
      Yes: "Yes",
      No: "No",
      "Is it work for you": "Is it work for you?",
      "Go to the store": "Go to the store",
      "Page not found": "Page not found",
      "There is no coupons to show": "There is no coupons to show",
      popupBlocked:
        "Pop-ups are blocked. Please disable the popup blocker and try again.",
      copyright: "Copyright © 2023 GloCoupon. All rights reserved.",
      Error: "Error",
      "Please try again.": "Please try again.",
      TryAgain: "Try again",
      "About Us": "About Us",
      "Website URL": "Website URL",
      seoDesc: "Who Are We",
    },
  },
  ar: {
    translation: {
      "Dashboard Home": "الصفحة الرئيسية",
      Stores: "المتجر",
      Categories: "فئات",
      "Glo Coupon online shopping offers":
        "عروض التسوق عبر الإنترنت من Glo Coupon",
      "Popular Stores": "المتاجر الشعبية",
      "Today's Best Coupons": "أفضل كوبونات اليوم",
      "Show Coupon": "إظهار الكوبون",
      "View all": "عرض الجميع",
      Offers: "عروض",
      contact1: "لتصلك أقوى العروض وكوبونات الخصم",
      contact2: "أنضم لقائمة لعملائنا المميزين",
      "Contact Us": "تواصل معنا",
      "Who Are We": "من نحن",
      "Follow Us": "تابعنا",
      Subscribe: "اشتراك",
      "All Categories": "جميع التصنيفات",
      "All Stores": "جميع المتاجر",
      All: "الجميع",
      "Coupon code": "رمز الكوبون",
      Code: "الرمز ",
      Deals: "الصفقات",
      "Related Categories": "تصنيفات لها علاقة",
      "Popular Stores": "المتاجر المعروفة",
      "Top Stores": "افضل المتاجر",
      "Activate Deal": "تفعيل الصفقة",
      "Code Copied": "تم نسخ الرمز",
      "Paste This Coupon Code Checkout": "الصق رمز القسيمة الخروج",
      "Show Details": "عرض التفاصيل",
      "Search about:": "ابحث عن:",
      Yes: "نعم",
      No: "لا",
      "Is it work for you": "هل عمل لديك؟",
      "Go to the store": "اذهب الى المتجر",
      "Page not found": "لا وجود لهذه الصفحة",
      "There is no coupons to show": "لا يوجد كوبونات لعرضها",
      popupBlocked:
        "تم حظر النوافذ المنبثقة. الرجاء تعطيل مانع النوافذ المنبثقة وحاول مرة أخرى.",
      copyright: "حقوق النشر © 2023 GloCoupon. كل الحقوق محفوظة.",
      Error: "حدث خطأ",
      "Please try again.": "الرجاء المحاولة مرةم اخرى.",
      TryAgain: "اعد المحاولة",
      "About Us": "نبذة عنا",
      "Website URL": "رابط الموقع",
      seoDesc: "من نحن",
    },
  },
};
