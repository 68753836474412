import { Heading, Box } from "@chakra-ui/react";

export const MainTitle = ({
  title,
  pb = 5,
  pt = 2,
  px = 0,
  transform = "uppercase",
}) => {
  let transformClass = transform === "unset" ? "transUnset" : "uppercase";
  return (
    <Box
      marginY={14}
      display="flex"
      justifyContent="center"
      className="main_title-margin"
    >
      <Heading
        fontSize="34px"
        className={`main_title ${transformClass}`}
        pb={pb}
        pt={pt}
        px={px}
        textTransform="lowercase"
      >
        {title}
      </Heading>
    </Box>
  );
};
