import React, { useEffect, useState } from "react";
import history from "../../../history";

import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import {
  AddUsedCouponAPI,
  AddStatusAPI,
  GetStatusesAPI,
} from "../../../api/landing/CouponAPI";
import { useTranslation } from "react-i18next";

import { MainButton } from "../button/MainButton";

import leftArrow from "../../../assets/images/icons/leftArrow2.svg";

export const ModalAlert = ({
  isOpen,
  onClose,
  title,
  desc,
  icon,
  hasDesc,
  confirmationModal = false,
  path,
  pathDraft,
  btnWidthFull,
  haveSaveDraft = true,
  isColorsSwitched,
  disableClose = false,
  isDelete = false,
  onPressSaveDraft,
  selectedID,
  errorMessage,
  setErrorMessage,
  renderRowsAfterDeletion,
  setDismissSave,
  isAssetModal = false,
  navLinkSubmit = false,
  assetVersionModal = false,
  linkText,
  closeOnOverlayClicked = false,
  setStoreUrlCopied,
  couponID,
  couponCode,
  storeUrlCopied,
}) => {
  const { t, i18n } = useTranslation();
  let modalProps;
  let hideCloseBtn = !disableClose || !confirmationModal ? false : true;
  const [usedCoupon, setUsedCoupon] = useState("");
  const [showBox, setShowBox] = useState(false);

  if (!disableClose) {
    modalProps = {
      onClose: onClose,
    };
  }
  if (!closeOnOverlayClicked) {
    modalProps = {
      ...modalProps,
      closeOnOverlayClicked: false,
    };
  }

  if (isAssetModal) {
    var lastItem = desc[desc.length - 1] || "";
    desc[desc.length - 1] = lastItem.replace(",", ".");
  }

  const handleDeleteItem = () => {
    renderRowsAfterDeletion(selectedID);
    onClose();
    if (assetVersionModal) {
      history.push("/AssetsHistory");
    }
  };
  const handleUsedCoupon = (status) => {
    AddStatusAPI({
      statusId: status,
      usedCouponId: usedCoupon,
    })
      .then((res) => {
        onClose();
        // setUsedCoupon(res)
        // setStoresData(res.storesMap);
        // setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!!couponID) {
      AddUsedCouponAPI({
        couponId: couponID,
      })
        .then((res) => {
          setUsedCoupon(res.used_coupon_id);
          // setStoresData(res.storesMap);
          // setIsLoading(false);
          //return res;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [couponID]);
  useEffect(() => {
    setTimeout(() => {
      setShowBox(true);
    }, 4000);
  }, []);

  return (
    <div className={disableClose ? "modal_disableClose" : ""}>
      <Modal isOpen={isOpen} {...modalProps}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.35)"
          backdropFilter="blur(2px)"
        />
        <ModalContent
          py={12}
          px={4}
          alignSelf="center"
          className="modal_content"
          borderRadius="25px"
          background="#022F59"
          maxWidth="620px"
        >
          {/* <ModalCloseButton
          onClick={() => {
            window.dataLayer.push({
              event: "close-coupon-modal",
            });
            setStoreUrlCopied("");
          }} */}
          {/* /> */}
          <div
            className="modal-close"
            onClick={() => {
              window.dataLayer.push({
                event: "close-coupon-modal",
              });
              setStoreUrlCopied("");
              onClose();
            }}
          >
            x
          </div>
          <Center flexDirection="column">
            <img
              className="info_icon"
              src={icon}
              width="72px"
              height="72px"
              alt="checked icon"
            />
            <ModalHeader
              textAlign="center"
              color="#fff"
              fontSize="30px"
              fontWeight="400"
              letterSpacing="-0.02em"
              px={5}
              mt={2}
              className="modal_header"
            >
              <div> {title}</div>

              <div className="modal_code"> {`${t("Code")}: ${couponCode}`}</div>
              {showBox && (
                <div className="modal_website">
                  {`${t("Website URL")}: `}
                  <a href={`${storeUrlCopied}`} target="_blank">
                    {storeUrlCopied}
                  </a>
                </div>
              )}
            </ModalHeader>
          </Center>
          {/* {hideCloseBtn && <ModalCloseButton />} */}
          {hasDesc && (
            <ModalBody textAlign="center">
              <Center>
                <Text fontSize="17px" color="#6AEFAB">
                  {desc}
                </Text>
              </Center>
            </ModalBody>
          )}

          {showBox && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box fontSize="18px" fontWeight="bold" color="#fff" marginY={3}>
                {t("Is it work for you")}
              </Box>
              <Box display="flex" gap="10px" marginBottom="10px">
                <Button
                  onClick={() => {
                    window.dataLayer.push({
                      event: "coupon-modal-no-button",
                    });
                    handleUsedCoupon(4);
                  }}
                >
                  {t("No")}
                </Button>
                <Button
                  onClick={() => {
                    window.dataLayer.push({
                      event: "coupon-modal-yes-button",
                    });
                    handleUsedCoupon(3);
                  }}
                  background="#6AEFAB"
                >
                  {t("Yes")}
                </Button>
                {/* <Button onClick={() => handleUsedCoupon(11)}>{t("No")}</Button>
                <Button
                  onClick={() => handleUsedCoupon(1)}
                  background="#6AEFAB"
                >
                  {t("Yes")}
                </Button> */}
              </Box>
            </Box>
          )}

          <hr
            style={{
              marginLeft: "-15px",
              marginRight: "-15px",
              color: "white",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          />
          <ModalFooter>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <NavLink to={path} color="#fff">
                <Text color="#fff">{linkText}</Text>
              </NavLink>
            </Flex>
          </ModalFooter>
          {/* <ModalFooter>{renderButtons(confirmationModal)}</ModalFooter> */}
        </ModalContent>
      </Modal>
    </div>
  );
};
