import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
  Badge,
} from "@chakra-ui/react";

import { GetCategoriesAPI } from "../../api/CategoryAPI";

import { MainButton } from "../../components/button/MainButton";
import { TablePagination2 } from "../../components/tablePagination/TablePagination2";
import Cookies from "js-cookie";

export const CategoriesTable = ({
  onDelModalOpen,
  CategoriesData,
  setSelectedID,
  setActiveOrIn,
}) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });

  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "IMAGE",
      selector: (row) => row.image,
      cell: (row) => (
        <Box color={`#007BB5`} paddingY="10px">
          <Image
            width="60px"
            height="60px"
            objectFit="contain"
            src={`https://www.glocoupon.com/api2/${row.image}`}
          />
        </Box>
      ),
    },
    {
      name: "NAME EN",
      selector: (row) => row.name_en,
    },

    {
      name: "ACTION",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          {row.active ? (
            <MainButton
              title="Deactivate"
              btnStyle="transparent"
              fontColor="danger"
              marginRight="5"
              onPress={() => {
                setActiveOrIn(0);
                setSelectedID(`deOrActive-${row.idCustom}`);
                onDelModalOpen();
              }}
            />
          ) : (
            <MainButton
              title="Activate"
              btnStyle="transparent"
              fontColor="dark"
              marginRight="5"
              onPress={() => {
                setActiveOrIn(1);
                setSelectedID(`deOrActive-${row.idCustom}`);
                onDelModalOpen();
              }}
            />
          )}
          <MainButton
            title="Details"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/Admin/Category/${row.idCustom}`}
          />
          {/* <MainButton
            title="Delete"
            btnStyle="transparent"
            fontColor="danger"
            marginRight="5"
            onPress={() => {
              setSelectedID(`delete-${row.idCustom}`);
              onDelModalOpen();
            }}
          /> */}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (CategoriesData?.categories?.length > 0 && !!CategoriesData) {
      CategoriesData.categories.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          name_en: `${item.name_en}`,
          active: parseInt(`${item.active}`),
          image: `${item?.image}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });
  }, []);

  const handleOnPageChange = (page) => {
    GetCategoriesAPI(page)
      .then((res) => {
        let data = [];

        if (res.categories.rows.length > 0) {
          res.categories.rows.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              name_en: `${item.name_en}`,
              active: parseInt(`${item.active}`),
              image: `${item?.image}`,
            };

            data.push(dataObj);
          });

          setDataArray(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="Category"
                hasDelete={true}
                totalRows={CategoriesData?.count}
                // pageSize={CategoriesData?.limit}
                pageSize={10}
                // pageSize={CategoriesData.pageSize}
                GetDataAPI={GetCategoriesAPI}
                handleOnPageChange={handleOnPageChange}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                haveCreateButton={true}
                path={`/Admin/Category`}
                buttonTitle="Create Category"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
