import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Box, Text, Image, Grid, GridItem } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.scss";
import whatsapp from "../../../assets/images/whatsapp.png";
import email from "../../../assets/images/email.png";
import instagram from "../../../assets/images/instagram.png";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`${styles.content} ${
        i18n.language !== "en" && styles.content_rtl
      }`}
    >
      <Grid templateColumns="repeat(3, 1fr)" gap={6} width="100%">
        <GridItem w="100%">
          <Text color="white" fontWeight="bold" fontSize="20px">
            <span
              style={{
                paddingBottom: "5px",
                borderBottom: "2px solid #6AEFAB",
                color: "white",
              }}
            >
              {t("Contact Us")}
            </span>
          </Text>
        </GridItem>
        <GridItem w="100%">
          <Text
            color="white"
            fontWeight="bold"
            fontSize="20px"
            textAlign="center"
          >
            <span
              style={{
                paddingBottom: "5px",
                borderBottom: "2px solid #6AEFAB",
                color: "white",
              }}
            >
              {t("Who Are We")}
            </span>
          </Text>
        </GridItem>
        <GridItem w="100%">
          <Text
            color="white"
            fontWeight="bold"
            fontSize="20px"
            textAlign="right"
          >
            <span
              style={{
                paddingBottom: "5px",
                borderBottom: "2px solid #6AEFAB",
                color: "white",
              }}
            >
              {t("Follow Us")}
            </span>
          </Text>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            marginTop="40px"
          >
            <NavLink
              className={styles.socialLink}
              to="#"
              onClick={(e) => {
                window.dataLayer.push({
                  event: "social-media-email-button",
                });
                window.location.href = "mailto:Glocouponn@gmail.com";
                e.preventDefault();
              }}
            >
              <Image alt="social media" src={email} />
            </NavLink>
            {/*  <NavLink className={styles.socialLink} to="/">
              <Image alt="social media" src={email} />
            </NavLink> */}
            <NavLink
              className={styles.socialLink}
              to={`https://instagram.com/globalnetwork.global?igshid=MzRlODBiNWFlZA==`}
              onClick={() =>
                window.dataLayer.push({
                  event: "social-media-instagram-button",
                })
              }
            >
              <Image alt="social media" src={instagram} />
            </NavLink>
          </Box>
        </GridItem>
      </Grid>

      <Box marginY={20} textAlign="center">
        <Text fontSize="16px" color="white">
          {t("copyright")}
        </Text>
      </Box>
    </div>
  );
};
