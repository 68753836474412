import axios from "axios";
import config from "../config";
import Cookies from "js-cookie";

const AxiosClient = axios.create({
  baseURL: config.api.baseURL,
});
export const AxiosClientGet = axios.create({
  baseURL: config.api.baseURL,
  headers: {
    //"Content-Type": "multipart/form-data",
    // "x-access-token": Cookies.get("token"),
    Authorization: `Bearer ${Cookies.get("token")}`,
    "Content-Type": "multipart/form-data",
  },
});

export const AxiosClientPost = axios.create({
  baseURL: config.api.baseURL,
  //withCredentials: true,
  headers: {
    //"Content-Type": "multipart/form-data",
    // "x-access-token": Cookies.get("token"),
    Authorization: `Bearer ${Cookies.get("token")}`,
  },
});

export const AxiosClientMultipart = axios.create({
  baseURL: config.api.baseURL,
  //"Content-Type": "application/x-www-form-urlencoded",
  //"Access-Control-Allow-Credentials": true,
  //withCredentials: true,
  headers: {
    Authorization: `Bearer ${Cookies.get("token")}`,
    "Content-Type": "multipart/form-data",
  },
});

export default AxiosClient;
