import React from "react";
import { ErrorMessage, Field } from "formik";
import { Text, Box } from "@chakra-ui/react";

import styles from "../forms.module.scss";

import view from "../../assets/images/icons/eye.svg";
import eyeSlash from "../../assets/images/icons/eye-slash.svg";

// - You can hide the label throw putting labelVisibility to false
export const InputFieldValidation = ({
  label,
  inputType,
  inputId,
  inputName,
  isRequired = true,
  labelVisibility = true,
  placeholder,
  errors,
  touched,
  dColumn = false,
  minNum,
  maxNum,
  fullWidth = "",
  password = false,
  showPassHandler,
  icon,
  onChange,
  values,
  step,
  uppercase = true,
  labelCenter,
  errorHeight0,
  isDisabled,
  noBg,
  hideLabel,
  directVal = false,
}) => {
  let inputProps;
  if (values) {
    if (directVal) {
      inputProps = {
        value: values,
      };
    } else {
      inputProps = {
        value: values[inputName],
      };
    }
  }

  let boxProps;
  let labelUppercaseClassname = uppercase ? `${styles.uppercase}` : "";
  labelUppercaseClassname += labelCenter ? ` ${styles.labelCenter} ` : "";

  let errorInputClassname =
    errors[inputName] && touched[inputName] && errors[inputName]
      ? `${styles.form_control} ${styles.form_control_error} ${
          dColumn ? "flex_column" : ""
        } `
      : `${styles.form_control} ${dColumn ? "flex_column" : ""}`;

  let formInputClassname = labelVisibility
    ? `${styles.form_control_input} ${fullWidth}`
    : `${styles.form_control_input} ${styles.form_control_full}  ${fullWidth}`;

  formInputClassname += labelCenter ? ` ${styles.textAlignCenter} ` : "";

  formInputClassname += noBg ? ` ${styles.fieldNoBg} ` : "";

  const haveOnChangeMethod = onChange ? (
    <Field
      disabled={isDisabled}
      type={inputType}
      name={inputName}
      id={inputName}
      placeholder={placeholder}
      min={minNum}
      max={maxNum}
      step={step}
      {...inputProps}
      onChange={(val) => {
        let value;
        if (inputType == "number") {
          if (!isNaN(parseFloat(val.target.value))) {
            value = parseFloat(val.target.value);
          } else {
            value = val.target.value;
          }
        } else {
          value = val.target.value;
        }
        onChange(inputName, value);
      }}
    />
  ) : (
    <Field
      disabled={isDisabled}
      type={inputType}
      name={inputName}
      id={inputName}
      placeholder={placeholder}
      min={minNum}
      max={maxNum}
      step={step}
    />
  );

  return (
    <Box className={errorInputClassname} {...boxProps}>
      {labelVisibility && (
        <label
          style={hideLabel === true ? { visibility: "hidden" } : {}}
          htmlFor={inputId}
          className={`${fullWidth} ${labelUppercaseClassname}`}
        >
          {label}
        </label>
      )}

      <div className={formInputClassname}>
        {haveOnChangeMethod}

        {password && (
          <div className="showPassword" onClick={() => showPassHandler()}>
            <img
              src={inputType === "password" ? view : eyeSlash}
              alt="eye icon"
              width="16px"
            />
          </div>
        )}
        {icon && (
          <div className="iconic_input">
            <img src={icon} alt="icon" />
          </div>
        )}

        {isRequired && (
          <ErrorMessage
            name={inputName}
            component="div"
            className={`${styles.error} ${errorHeight0 ? styles.h_0 : ""} ${
              labelCenter ? styles.textAlignCenter : ""
            }`}
          />
        )}
      </div>
    </Box>
  );
};
