import React from "react";

import { Box, Divider, Flex } from "@chakra-ui/react";

import { MainButton } from "../../components/button/MainButton";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";

export const BannerForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  formikValues,
  dirty,
  isValid,
  handleOnSubmit,
  dropdownObj,
}) => {
  return (
    <>
      <Box
        maxW={["100%", "100%", "70%"]}
        width={["100%", "100%", "70%"]}
        alignSelf="flex-start"
      >
        <SelectListMulti
          dropdownItems={dropdownObj.copuons}
          label="coupon_id"
          inputId="coupon_id"
          inputName="coupon_id"
          placeholder="Select"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues?.coupon_id}
          onChange={setFieldValue}
          onBlur={handleBlur}
          uppercase={true}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          <MainButton
            title="Cancel"
            btnStyle="transparent"
            fontColor="light"
            navLink={true}
            path={`/Admin/BannersHistory`}
          />

          <MainButton
            title="Submit"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
