import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost, AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//Categories
export const GetCategoriesAPI = async (page = 1) => {
  const URL = `/categories?page=${page}&limit=12`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCategoryByIdAPI = async (id) => {
  const URL = `/categories/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddCategoryAPI = async (payload) => {
  const URL = "/categories";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateCategoryAPI = async (payload) => {
  // const URL = `/addCategory/${id}`;
  const URL = `/categories`;
  try {
    const response = await AxiosClientMultipart.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCategoriesFullAPI = async () => {
  const URL = `/categories`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeactiveActiveCategoryByIdAPI = async (id, active = 0) => {
  const URL = `/categories/${id}?number=${active}`;
  try {
    const response = await AxiosClientPost.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
