import { AxiosClientGet } from "./index";

//Categories
export const GetCategoriesAPI = async () => {
  const URL = `/categories`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCategoryByIdAPI = async (id) => {
  const URL = `/categories/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAllTypesAPI = async () => {
  const URL = `/types`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetCouponsByCategoryAPI = async (id) => {
  const URL = `/couponsCategory/${id}`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
