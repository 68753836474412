import { Box, Text, Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import styles from "./brandCard.module.scss";

export const BrandCard = ({
  title,
  url,
  btnStyle,
  btnWidthFull,
  fontColor,
  reverseRow,
  icon,
  marginRight = "0",
  marginLeft = "0",
  type = "button",
  border,
  borderColor,
  btnHeight = "unset",
  navLink,
  path,
  fSize = "md",
  mt = "",
  className,
  width = 18,
}) => {
  let btnClassname;

  switch (btnStyle) {
    case "gray":
      btnClassname = `${styles.btn_gray}`;
      break;
    case "transparent":
      btnClassname = `${styles.btn_transparent}`;
      break;
    case "transparent2":
      btnClassname = `${styles.btn_transparent2}`;
      break;
    case "navyBlue":
      btnClassname = `${styles.btn_navyBlue}`;
      break;
    case "navyBlue2":
      btnClassname = `${styles.btn_navyBlue2}`;
      break;
    case "green":
      btnClassname = `${styles.btn_green}`;
      break;
    case "gray_border":
      btnClassname = `${styles.btn_gray_border}`;
      break;
    case "secondary":
      btnClassname = `${styles.btn_secondary}`;
      break;
    default:
      btnClassname = `${styles.btn_primary}`;
  }

  switch (fontColor) {
    case "dark":
      btnClassname += ` ${styles.color_dark} `;
      break;
    case "light":
      btnClassname += ` ${styles.color_light} `;
      break;
    case "danger":
      btnClassname += ` ${styles.color_danger} `;
      break;
    case "assets":
      btnClassname += ` ${styles.color_assets} `;
      break;
  }

  if (mt === "mt7") {
    btnClassname += ` ${styles.mt7} `;
  }

  btnClassname += btnWidthFull ? ` ${styles.w_100} ` : "";

  btnClassname +=
    border && borderColor === "gray" ? ` ${styles.border_gray} ` : "";

  if (reverseRow === true) {
    btnClassname += ` ${styles.flex_row_reverse} `;
  }
  let fontSizeClassname;

  switch (fSize) {
    case "md":
      fontSizeClassname = `title_sm ${styles.pointerNone}`;
      break;
    case "sm":
      fontSizeClassname = `title_sm2 ${styles.pointerNone}`;
      break;
    case "sm_x2":
      fontSizeClassname = `title_sm_x2 ${styles.pointerNone}`;
      break;
  }

  return (
    <>
      <NavLink
        className={`${styles.center} ${styles.brandLink}  ${
          width === 22 && styles.width22
        }`}
        to={path}
        onClick={() =>
          window.dataLayer.push({
            event: "brand-card",
          })
        }
      >
        <Box
          display="flex"
          alignItems="center"
          className={`${styles.brand} ${
            reverseRow === true ? styles.flex_row_reverse : ""
          }`}
        >
          {icon && (
            // <Box mr={marginRight} ml={marginLeft}>
            <img src={icon} alt="icon" />
            // </Box>
          )}

          {/* <Text className={fontSizeClassname}>{title}</Text> */}
        </Box>
      </NavLink>
    </>
  );
};
