import React, { useState } from "react";

import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  GridItem,
  Text,
  Image,
  Input,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { TextArea } from "../../components/textArea/TextArea";
import { MainButton } from "../../components/button/MainButton";

export const AboutForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  formikValues,
  dirty,
  isValid,
  handleOnSubmit,
  generalData,
}) => {
  return (
    <>
      <Box
        maxW={["100%", "100%", "70%"]}
        width={["100%", "100%", "70%"]}
        alignSelf="flex-start"
      >
        <TextArea
          label="description_en"
          inputId="description_en"
          inputName="description_en"
          placeholder="Add a description en"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="description_ar"
          inputId="description_ar"
          inputName="description_ar"
          placeholder="Add a description_ar"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          <MainButton
            title="Cancel"
            btnStyle="transparent"
            fontColor="light"
            navLink={true}
            path={`/Admin/About`}
          />

          <MainButton
            title="Submit"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
