import react, { useState, useEffect, useCallback, useRef } from "react";

import {
  Box,
  Text,
  Center,
  Spinner,
  Image,
  InputRightElement,
  InputLeftElement,
  Input,
  InputGroup,
  Button,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  useDisclosure,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { MainTitle } from "../../components/landing/mainTitle/MainTitle";
import { MainBox } from "../../components/landing/mainBox/MainBox";
import { BrandCard } from "../../components/landing/brandCard/BrandCard";
import { ModalAlert2 } from "../../components/landing//modalAlert/ModalAlert2";

import logo from "../../assets/images/logo.png";

import { GetStoresAPI } from "../../api/landing/StoreAPI";
import checked from "../../assets/images/success.png";

export const AllStores = () => {
  const { t, i18n } = useTranslation();
  const baseUrl = "/" + i18n.language;
  const [errorMessage, setErrorMessage] = useState(`${t("Please try again.")}`);
  const [isLoading, setIsLoading] = useState(true);

  const [StoresData, setStoresData] = useState([]);
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetStoresAPI()
      .then((res) => {
        setStoresData(res.storesMap);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        onErrorOpen();
        setErrorMessage(err?.response?.data?.message || t("Please try again."));
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <div>
      <Helmet>
        <title>All Stores</title>
        <meta name="description" content="All Stores" />
      </Helmet>

      <Box marginTop="15px">
        <MainTitle px={16} pt={8} pb={8} title={t("All Stores")} />
      </Box>
      <Box>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink color="#706F6F" href="/">
              {t("Home")}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink color="#022E5B" href="/AllStores">
              {t("All Stores")}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>

      <Box display="flex" flexWrap="wrap" marginTop="20px" gap="2%">
        {isLoading ? (
          <>
            <MainBox>
              <Box paddingTop={12} paddingBottom={12}>
                <Center>
                  <Spinner />
                </Center>
              </Box>
            </MainBox>
          </>
        ) : (
          <>
            {StoresData.length > 0 ? (
              <>
                {StoresData?.map((el, ind) => {
                  // if (ind > 4) return;
                  return (
                    <BrandCard
                      path={`${baseUrl}/store/${el.id}`}
                      title={`${
                        i18n.language == "en" ? el.name_en : el.name_ar
                      }`}
                      icon={`https://www.glocoupon.com/api2/${el.image}`}
                      key={el.id}
                      width={22}
                    />
                  );
                })}
              </>
            ) : (
              <Box
                textAlign="center"
                paddingY={14}
                borderRadius="20px"
                background="white"
                fontSize="24px"
                width="100%"
              >
                {t("There is no coupons to show")}
              </Box>
            )}
          </>
        )}
      </Box>

      <ModalAlert2
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={t("Error")}
        desc={errorMessage}
        btnWidthFull={true}
        icon={checked}
        hasDesc={true}
        isError={true}
      />
    </div>
  );
};
