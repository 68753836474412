import { useState, useEffect } from "react";
import { Box, Flex, HStack, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { GetTypesAPI } from "../../api/HomeAPI";

import { MainButton } from "../../components/button/MainButton";
import { TablePagination2 } from "../../components/tablePagination/TablePagination2";

export const TypesTable = ({
  onDelModalOpen,
  TypesData,
  setSelectedID,
  setActiveOrIn,
}) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });

  const [dataArray, setDataArray] = useState([]);
  const columns = [
    {
      name: "Types ID",
      selector: (row) => row.idCustom,
    },
    {
      name: "TYPE NAME EN",
      selector: (row) => row.name_en,
    },
    {
      name: "TYPE NAME AR",
      selector: (row) => row.name_ar,
    },

    {
      name: "ACTION",
      selector: (row) => row.action,
      cell: (row) => (
        <Flex
          justifyContent="space-between"
          gap="15px"
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <MainButton
            title="Details"
            btnStyle="transparent"
            fontColor="dark"
            marginRight="5"
            navLink={true}
            path={`/Admin/Types/${row.idCustom}`}
          />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let data = [];
    if (TypesData?.types?.length > 0 && !!TypesData) {
      TypesData.types.forEach((item) => {
        let dataObj = {
          id: `${item.id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.id}`,
          name_en: `${item.type_en}`,
          name_ar: `${item.type_ar}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });
  }, []);

  const handleOnPageChange = (page) => {
    GetTypesAPI(page)
      .then((res) => {
        let data = [];

        if (res.types.length > 0) {
          res.types.forEach((item) => {
            let dataObj = {
              id: `${item.id + Math.floor(Math.random() * Date.now())}`,
              idCustom: `${item.id}`,
              name_en: `${item.type_en}`,
              name_ar: `${item.type_ar}`,
            };

            data.push(dataObj);
          });

          setDataArray(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="Types"
                hasDelete={true}
                totalRows={TypesData?.count}
                pageSize={10}
                // pageSize={TypesData?.limit}
                // pageSize={TypesData.pageSize}
                GetDataAPI={GetTypesAPI}
                handleOnPageChange={handleOnPageChange}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                haveCreateButton={true}
                path={`/Admin/Types`}
                buttonTitle="Create Types"
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
