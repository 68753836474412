import React from "react";
import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const ModalAlert2 = ({
  isOpen,
  onClose,
  title,
  desc,
  icon,
  confirmationModal = false,
  disableClose = false,
  isAssetModal = false,
  children,
  isError = false,
}) => {
  const { t, i18n } = useTranslation();
  let modalProps;
  let hideCloseBtn = !disableClose || !confirmationModal ? false : true;

  if (!disableClose) {
    modalProps = {
      onClose: onClose,
    };
  }

  if (isAssetModal) {
    var lastItem = desc[desc.length - 1] || "";
    desc[desc.length - 1] = lastItem.replace(",", ".");
  }

  return (
    <div className={disableClose ? "modal_disableClose" : ""}>
      <Modal isOpen={isOpen} {...modalProps}>
        <ModalOverlay
          background="rgba(29, 31, 34, 0.35)"
          backdropFilter="blur(2px)"
        />
        <ModalContent
          py={12}
          px={4}
          alignSelf="center"
          className="modal_content"
          borderRadius="25px"
          background="#022F59"
          maxWidth="620px"
        >
          <ModalCloseButton
            onClick={() => {
              // setStoreUrlCopied("");
              window.dataLayer.push({
                event: "close-error-modal",
              });
            }}
          />
          <Center flexDirection="column">
            {/* <img
              className="info_icon"
              src={icon}
              width="72px"
              height="72px"
              alt="checked icon"
            /> */}
            <svg width="72" height="73" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <circle opacity="0.9" cx="12" cy="12" r="10" fill="white" />
                <path
                  d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z"
                  fill="#b91b1b"
                />
              </g>
            </svg>
            <ModalHeader
              textAlign="center"
              color="#fff"
              fontSize="30px"
              fontWeight="400"
              letterSpacing="-0.02em"
              px={5}
              mt={2}
              className="modal_header"
            >
              {title}
            </ModalHeader>
          </Center>
          {/* {hideCloseBtn && <ModalCloseButton />} */}
          <ModalCloseButton />
          <ModalBody textAlign="center">
            <Center>
              {children}
              {/* <Button onClick={() => {}}>{t("tryAgain")}</Button> */}
              <Box display="flex" flexDirection="column">
                <Text fontSize="17px" color="#fff">
                  {desc}
                </Text>
                <Button
                  marginTop={5}
                  onClick={() => {
                    window.dataLayer.push({
                      event: "try-again-button",
                    });
                    window.location.reload();
                  }}
                >
                  {t("TryAgain")}
                </Button>
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
