import { AxiosClientMultipart, AxiosClientPost, AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//Coupons
export const GetCouponsAPI = async (page = 1) => {
  const URL = `/coupons`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetCouponsFullAPI = async (page = 1) => {
  const URL = `/coupons`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const SearchCouponeNameAPI = async (payload) => {
  const URL = "/coupons";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddUsedCouponAPI = async (payload) => {
  const URL = "/usedCoupons";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddStatusAPI = async (payload) => {
  const URL = "/status";
  try {
    const response = await AxiosClientMultipart.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetStatusesAPI = async (id) => {
  const URL = `/statuses`;
  try {
    const response = await AxiosClientGet.get(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetCouponByIdAPI = async (id) => {
  const URL = `/coupons/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
