import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";

import { ChakraProvider } from "@chakra-ui/react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Helmet } from "react-helmet";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// import { parseToken } from "./utils/Auth";
import { Sidebar } from "./layouts/sidebar";
import { Main } from "./layouts/main";
import { Main as MainLanding } from "./layouts/landing/main";
import { Login } from "./pages/Login";
import { useSearchParams } from "react-router-dom";
// routes = !change ? routes_1 : routes_2;
// import "./assets/styles/global.scss";
import languages from "./languages";

i18n.on("languageChanged", function (lng) {
  // if the language we switched to is the default language we need to remove the /en from URL
  console.log("lng", lng);
  if (
    !window.location.pathname.includes("/en") &&
    !window.location.pathname.includes("/ar") &&
    !window.location.pathname.toLowerCase().includes("admin")
  ) {
    window.location.replace(window.location.origin + "/en");
  }
  // if (lng === i18n.options.fallbackLng[0]) {
  //   if (window.location.pathname.includes("/" + i18n.options.fallbackLng[0])) {
  // const newUrl = window.location.pathname.replace(
  //   "/" + i18n.options.fallbackLng[0],
  //   "/"
  // );
  // // console.log("newUrl", newUrl);
  // // console.log("newUrl 2", window.location.pathname);
  // window.location.replace(newUrl);

  //   }
  // }
});
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: languages,
    // lng: "en",
    fallbackLng: ["en"],

    whitelist: ["en", "ar"],
    // interpolation: {
    //   escapeValue: false,
    // },

    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
  });

i18n.languages = ["en", "ar"];

function App() {
  let mainCondition = window.location.href
    .toLocaleLowerCase()
    .includes("admin");
  const bootstrap =
    !mainCondition && import("bootstrap/dist/css/bootstrap.min.css");
  const styles = !mainCondition
    ? import("./assets/styles/landing/global.scss")
    : import("./assets/styles/global.scss");

  const { t, i18n } = useTranslation();

  const renderRef = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(true);
  const [user, setUser] = useState(false);

  const handleUserAuth = (user) => {
    let userRole = user.roles?.[0] || "none";
    if (true) {
      return (
        <ChakraProvider>
          <div className="main_content">
            <Sidebar user={user} isOpen={isOpen} setIsOpen={setIsOpen} />
            <Main user={user} isOpen={isOpen} />
          </div>
        </ChakraProvider>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (renderRef.current) return;
    renderRef.current = true;
    if (Cookies.get("token")) {
      let token = Cookies.get("token");
      setUser(jwt_decode(token));
      // console.log("user==>", user, token);
    }

    if (searchParams.get("token")) {
      let token = searchParams.get("token");
      setUser(jwt_decode(token));
      Cookies.set("token", token);
      setSearchParams("");
    }
    if (!window.location.href.toLocaleLowerCase().includes("admin")) {
      if (
        window.location.pathname.includes("/" + i18n.options.fallbackLng[0])
      ) {
        i18n.changeLanguage("en");
      } else if (window.location.pathname.includes("/ar")) {
        i18n.changeLanguage("ar");
      }
    }
  }, []);

  return (
    <>
      {!mainCondition ? (
        <ChakraProvider>
          <Helmet>
            <title>Glo Coupon</title>
            <meta name="description" content="Helmet application" />
          </Helmet>

          <div
            className={`landing_content ${
              i18n.language !== "en" && "landing_content_RTL"
            }`}
          >
            <MainLanding isOpen={isOpen} />
          </div>
        </ChakraProvider>
      ) : (
        <>{user ? handleUserAuth(user) : <Login setUser={setUser} />}</>
      )}
    </>
  );
}

export default App;
