import React, { forwardRef, useState, useCallback, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import {
  Box,
  Text,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  InputRightElement,
  InputLeftElement,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { GetCategoriesAPI } from "../../../api/landing/CategoryAPI";
import { GetStoresAPI } from "../../../api/landing/StoreAPI";
import styles from "./header.module.scss";

import logoLG from "../../../assets/images/logo.png";
import arrowDown from "../../../assets/images/icons/Cheveron.svg";
import lang from "../../../assets/images/lang.png";
import searchIcon from "../../../assets/images/search.png";

export const Header = forwardRef((props, ref) => {
  const { i18n, t } = useTranslation();
  const [allCategories, setAllCategories] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState(true);
  const baseUrl = "/" + i18n.language;

  function changeLanguage(e) {
    i18n.changeLanguage(e);
  }
  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetCategoriesAPI()
      .then((res) => {
        setAllCategories(res.categories.rows);
      })
      .catch((err) => {
        console.log(err);
      });
    GetStoresAPI()
      .then((res) => {
        setAllStores(res.storesMap);
        console.log("res.storesMap 2", res.storesMap);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <nav
      className={`${styles.navbar} ${!props.isOpen && styles.sidebarOpen} ${
        i18n.language !== "en" && styles.navbar_rtl
      }`}
      ref={ref}
    >
      <HStack
        spacing="auto"
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <NavLink to={baseUrl}>
            <Image
              src={logoLG}
              className={styles.logo}
              alt="logo"
              onClick={() =>
                window.dataLayer.push({
                  event: "home-nav-items",
                })
              }
            />
          </NavLink>

          <Box
            className={`navbar-content ${styles.hiddenMobile}`}
            marginLeft={i18n.language == "en" ? "30px" : "0"}
            marginRight={i18n.language !== "en" ? "30px" : "0"}
            display="flex"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Box className="btn_profile">
                <Menu autoSelect={false}>
                  <MenuButton
                    as={Button}
                    bg="transparent"
                    rightIcon={<img src={arrowDown} alt="arrow icon" />}
                  >
                    <Text font color="#002F5B">
                      {t("Stores")}
                    </Text>
                  </MenuButton>
                  <MenuList maxHeight="400px" overflowY="auto">
                    <Link
                      to={`${baseUrl}/AllStores`}
                      onClick={() =>
                        window.dataLayer.push({
                          event: "store-dropdown-items",
                        })
                      }
                    >
                      <MenuItem>{t("All Stores")}</MenuItem>
                    </Link>
                    {allStores.map((el) => {
                      return (
                        <Link
                          to={`${baseUrl}/store/${el.id}`}
                          onClick={() =>
                            window.dataLayer.push({
                              event: "store-dropdown-items",
                            })
                          }
                        >
                          <MenuItem>
                            {i18n.language == "en" ? el.name_en : el.name_ar}
                          </MenuItem>
                        </Link>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
              <Box className={`${styles.dropdownWrap} btn_profile`}>
                <Menu autoSelect={false} className={`${styles.dropdownWrap}`}>
                  <MenuButton
                    as={Button}
                    bg="transparent"
                    rightIcon={<img src={arrowDown} alt="arrow icon" />}
                  >
                    <Text font color="#002F5B">
                      {t("Categories")}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    <Link
                      to={`${baseUrl}/AllCategories`}
                      onClick={() =>
                        window.dataLayer.push({
                          event: "category-dropdown-items",
                        })
                      }
                    >
                      <MenuItem>{t("All Categories")}</MenuItem>
                    </Link>
                    {allCategories.map((el) => {
                      return (
                        <Link
                          to={`${baseUrl}/category/${el.id}`}
                          onClick={() =>
                            window.dataLayer.push({
                              event: "category-dropdown-items",
                            })
                          }
                        >
                          <MenuItem>
                            {i18n.language == "en" ? el.name_en : el.name_ar}
                          </MenuItem>
                        </Link>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
              <Box className="btn_profile">
                <Link
                  to={`${baseUrl}/About`}
                  onClick={() =>
                    window.dataLayer.push({
                      event: "about-nav-items",
                    })
                  }
                >
                  <Text
                    fontSize="19px"
                    fontWeight="300"
                    color="#002F5B"
                    paddingX={4}
                  >
                    {t("About Us")}
                  </Text>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={` ${styles.hiddenMobile}`}
        >
          <Box display="flex" alignItems="center">
            <Box>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  paddingLeft="65px"
                  variant="filled"
                  placeholder=""
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                {i18n.language === "en" ? (
                  <InputLeftElement width="4.5rem">
                    <NavLink
                      to={`${baseUrl}/Search`}
                      state={{ search: `${searchText}` }}
                      onClick={() =>
                        window.dataLayer.push({
                          event: "search-nav-bar",
                        })
                      }
                    >
                      <Image alt="search" src={searchIcon} width="25px" />
                    </NavLink>
                  </InputLeftElement>
                ) : (
                  <InputRightElement width="4.5rem">
                    <NavLink
                      to={`${baseUrl}/Search`}
                      state={{ search: `${searchText}` }}
                      onClick={() =>
                        window.dataLayer.push({
                          event: "search-nav-bar",
                        })
                      }
                    >
                      <Image alt="search" src={searchIcon} width="25px" />
                    </NavLink>
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginLeft={i18n.language == "en" ? "30px" : "0"}
              marginRight={i18n.language !== "en" ? "30px" : "0"}
            >
              {i18n.language == "en" ? (
                <button
                  onClick={() => {
                    window.dataLayer.push({
                      event: "language-nav-button",
                    });

                    changeLanguage("ar");
                    window.location.replace(
                      window.location.href.replace("en", "ar")
                    );
                  }}
                  value="ar"
                >
                  <Box cursor="pointer" display="flex" alignItems="center">
                    <Image
                      alt="language"
                      src={lang}
                      width="25px"
                      height="24px"
                      marginRight="5px"
                    />
                    <Text fontSize="18px">AR</Text>
                  </Box>
                </button>
              ) : (
                <button
                  onClick={() => {
                    window.dataLayer.push({
                      event: "language-nav-button",
                    });
                    changeLanguage("en");
                    window.location.replace(
                      window.location.href.replace("ar", "en")
                    );
                  }}
                  value="en"
                >
                  <Box cursor="pointer" display="flex" alignItems="center">
                    <Image
                      alt="language"
                      src={lang}
                      width="25px"
                      height="24px"
                      marginRight="5px"
                    />
                    <Text fontSize="18px">ENG</Text>
                  </Box>
                </button>
              )}
            </Box>
          </Box>
        </Box>

        <Box className={`${styles.menuWrapper}`}>
          <Box onClick={() => setOpen(!open)}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <rect
                  fill="#000000"
                  x="4"
                  y="5"
                  width="16"
                  height="3"
                  rx="1.5"
                />
                <path
                  d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </g>
            </svg>
          </Box>
          {open && (
            <Box className={`${styles.menu}`}>
              <Box>
                <Box
                  className="navbar-content"
                  marginLeft={i18n.language == "en" ? "30px" : "0"}
                  marginRight={i18n.language !== "en" ? "30px" : "0"}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Box className="btn_profile">
                    <Menu autoSelect={false}>
                      <MenuButton
                        as={Button}
                        bg="transparent"
                        rightIcon={<img src={arrowDown} alt="arrow icon" />}
                      >
                        <Text font color="#002F5B">
                          {t("Stores")}
                        </Text>
                      </MenuButton>
                      <MenuList>
                        <Link
                          to={`${baseUrl}/AllStores`}
                          onClick={() =>
                            window.dataLayer.push({
                              event: "store-dropdown-items",
                            })
                          }
                        >
                          <MenuItem>{t("All Stores")}</MenuItem>
                        </Link>
                        {allStores.map((el) => {
                          return (
                            <Link
                              to={`${baseUrl}/store/${el.id}`}
                              onClick={() =>
                                window.dataLayer.push({
                                  event: "store-dropdown-items",
                                })
                              }
                            >
                              <MenuItem>
                                {i18n.language == "en"
                                  ? el.name_en
                                  : el.name_ar}
                              </MenuItem>
                            </Link>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box className={`${styles.dropdownWrap} btn_profile`}>
                    <Menu
                      autoSelect={false}
                      className={`${styles.dropdownWrap}`}
                    >
                      <MenuButton
                        as={Button}
                        bg="transparent"
                        rightIcon={<img src={arrowDown} alt="arrow icon" />}
                      >
                        <Text font color="#002F5B">
                          {t("Categories")}
                        </Text>
                      </MenuButton>
                      <MenuList>
                        <Link
                          to={`${baseUrl}/AllCategories`}
                          onClick={() =>
                            window.dataLayer.push({
                              event: "category-dropdown-items",
                            })
                          }
                        >
                          <MenuItem>{t("All Categories")}</MenuItem>
                        </Link>
                        {allCategories.map((el) => {
                          return (
                            <Link
                              to={`${baseUrl}/category/${el.id}`}
                              onClick={() =>
                                window.dataLayer.push({
                                  event: "category-dropdown-items",
                                })
                              }
                            >
                              <MenuItem>
                                {i18n.language == "en"
                                  ? el.name_en
                                  : el.name_ar}
                              </MenuItem>
                            </Link>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box className="btn_profile">
                    <Link
                      to={`${baseUrl}/About`}
                      onClick={() =>
                        window.dataLayer.push({
                          event: "about-nav-items",
                        })
                      }
                    >
                      <Text
                        fontSize="16px"
                        color="#002F5B"
                        fontWeight="300"
                        paddingBottom={2}
                      >
                        {t("About Us")}
                      </Text>
                    </Link>
                  </Box>
                  <Box>
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        paddingLeft="65px"
                        variant="filled"
                        placeholder=""
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      {i18n.language === "en" ? (
                        <InputLeftElement width="4.5rem">
                          <NavLink
                            to={`${baseUrl}/Search`}
                            state={{ search: `${searchText}` }}
                            onClick={() =>
                              window.dataLayer.push({
                                event: "search-nav-bar",
                              })
                            }
                          >
                            {/* <Button
     h="1.75rem"
     size="sm" onClick={handleClick} 
   > */}
                            <Image alt="search" src={searchIcon} width="25px" />
                            {/* </Button> */}
                          </NavLink>
                        </InputLeftElement>
                      ) : (
                        <InputRightElement width="4.5rem">
                          <NavLink
                            to={`${baseUrl}/Search`}
                            state={{ search: `${searchText}` }}
                            onClick={() =>
                              window.dataLayer.push({
                                event: "search-nav-bar",
                              })
                            }
                          >
                            {/* <Button
                            h="1.75rem"
                            size="sm" onClick={handleClick} 
                          > */}
                            <Image alt="search" src={searchIcon} width="25px" />
                            {/* </Button> */}
                          </NavLink>
                        </InputRightElement>
                      )}
                    </InputGroup>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    marginLeft={i18n.language == "en" ? "30px" : "0"}
                    marginRight={i18n.language !== "en" ? "30px" : "0"}
                  >
                    {i18n.language == "en" ? (
                      <button
                        onClick={() => {
                          window.dataLayer.push({
                            event: "language-nav-button",
                          });
                          changeLanguage("ar");
                          window.location.replace(
                            window.location.href.replace("en", "ar")
                          );
                        }}
                        value="ar"
                      >
                        <Box
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                        >
                          <Image
                            alt="language"
                            src={lang}
                            width="25px"
                            height="24px"
                            marginRight="5px"
                          />
                          <Text fontSize="18px">AR</Text>
                        </Box>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          window.dataLayer.push({
                            event: "language-nav-button",
                          });
                          changeLanguage("en");
                          window.location.replace(
                            window.location.href.replace("ar", "en")
                          );
                        }}
                        value="en"
                      >
                        <Box
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                        >
                          <Image
                            alt="language"
                            src={lang}
                            width="25px"
                            height="24px"
                            marginRight="5px"
                          />
                          <Text fontSize="18px">ENG</Text>
                        </Box>
                      </button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </HStack>
    </nav>
  );
});
