import React from "react";

import { Box, Divider, Flex } from "@chakra-ui/react";

import { MainButton } from "../../components/button/MainButton";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import { TextArea } from "../../components/textArea/TextArea";

export const TypesForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  formikValues,
  dirty,
  isValid,
  handleOnSubmit,
  dropdownObj,
}) => {
  return (
    <>
      <Box
        maxW={["100%", "100%", "70%"]}
        width={["100%", "100%", "70%"]}
        alignSelf="flex-start"
      >
        <TextArea
          label="Type ar"
          inputId="type_ar"
          inputName="type_ar"
          placeholder="Add a type ar"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="Type en"
          inputId="type_en"
          inputName="type_en"
          placeholder="Add a type en"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          <MainButton
            title="Cancel"
            btnStyle="transparent"
            fontColor="light"
            navLink={true}
            path={`/Admin/TypesHistory`}
          />

          <MainButton
            title="Submit"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
