import React, { useState, useRef, useEffect } from "react";

import { NavLink } from "react-router-dom";
import { Accordion, Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { activeMenuItem } from "../../utils/General";
//import { parseToken } from "../../utils/Auth";

import styles from "./sidebar.module.scss";

export const Sidebar = ({ user, isOpen, setIsOpen }) => {
  const activeMenu = activeMenuItem();
  //let userRole = user.roles?.[0] || "";
  let userRole = "admin";

  //const [isOpen, setIsOpen] = useState(true);
  const ref = useRef(null);
  const sideRef = useRef(null);

  const rotateTransition = {
    type: "spring",
    delay: isOpen ? 0 : 0.1,
    bounce: 0.1,
    duration: 0.3,
  };

  const yTransition = {
    type: "spring",
    delay: !isOpen ? 0 : 0.1,
    bounce: 0.1,
    duration: 0.3,
  };

  // close sidebar on browser previous button
  // window.addEventListener('popstate', (event) => {
  //   setIsOpen(true)
  // });

  const handleSidebar = () => {
    //console.log("open");
    setIsOpen(!isOpen);
  };
  const handleAccordBtn = (event) => {
    if (isOpen) {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  const handleClickOutside = (event) => {
    if (sideRef.current && !sideRef.current.contains(event.target)) {
      setIsOpen(true);
    }
  };

  //on click outside the sidebar this will handle close the side bar
  /* useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []); */

  /* useEffect(() => {
    console.log(
      "mas1",
      window.location.href.toLowerCase().includes("masterdata"),
      !document.querySelector(".masterData").classList.contains("masterActive")
    );
    if (window.location.href.toLowerCase().includes("masterdata")) {
      document
        .querySelector(".masterData")
        .classList.remove(`${styles.active}`);
    }
  }); */

  return (
    <div ref={sideRef}>
      <Box
        bg="#002A5C"
        px="24px"
        pt="28px"
        pb="60px"
        className={
          isOpen
            ? `${styles.wrapper} ${styles.closed} closed`
            : `${styles.wrapper} ${styles.open}`
        }
        //sidebar open on hover
        /*  onMouseEnter={(e) => {
          //console.log(e.target.classList.contains("btn_burger"));
          if (!e.target.classList.contains("btn_burger")) {
            if (isOpen) {
              setTimeout(() => {
                setIsOpen(!isOpen);
              }, 50);
            }
          }
        }}
        onMouseLeave={(e) => {
          if (!e.target.classList.contains("btn_burger")) {
            if (!isOpen) {
              setTimeout(() => {
                setIsOpen(!isOpen);
              }, 50);
            }
          }
        }} */
      >
        <Box className={`${styles.btn_burger_wrapper} btn_burger`}>
          <button
            onClick={() => handleSidebar()}
            className={`${styles.centerBurger}`}
          >
            <Box className={styles.content}>
              {/*  <button className="container" onClick={isOpen ? closeMenu : openMenu}> */}
              <motion.div
                className={`${styles.topWrapper}`}
                animate={{ rotate: isOpen ? 0 : 45 }}
                transition={rotateTransition}
              >
                <motion.div
                  className={`${styles.topBread}`}
                  animate={{ y: isOpen ? 0 : 5 }}
                  transition={yTransition}
                />
              </motion.div>
              <motion.div
                className={`${styles.centerWrapper}`}
                animate={{ rotate: isOpen ? 0 : -45 }}
                transition={rotateTransition}
              >
                <motion.div
                  className={`${styles.centerBread}`}
                  transition={yTransition}
                />
              </motion.div>
              <motion.div
                className={`${styles.bottomWrapper}`}
                animate={{ rotate: isOpen ? 0 : -45 }}
                transition={rotateTransition}
              >
                <motion.div
                  className={`${styles.bottomBread}`}
                  animate={{ y: isOpen ? 0 : -5 }}
                  transition={yTransition}
                />
              </motion.div>
            </Box>
            {/*  </button> */}
            {/* <img src={burgerMenu} width="32px" alt="burger menu icon" /> */}
          </button>
        </Box>

        <Box className={styles.content} mt="50px">
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` + (!isActive ? "" : ` ${styles.active}`)
              }
              end
              to="/Admin/"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${styles.iconPos}`}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 5H6C5.44772 5 5 5.44772 5 6V8C5 8.55228 5.44772 9 6 9H8C8.55228 9 9 8.55228 9 8V6C9 5.44772 8.55228 5 8 5ZM6 3C4.34315 3 3 4.34315 3 6V8C3 9.65685 4.34315 11 6 11H8C9.65685 11 11 9.65685 11 8V6C11 4.34315 9.65685 3 8 3H6Z"
                      fill="#6F767E"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 15H6C5.44772 15 5 15.4477 5 16V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V16C9 15.4477 8.55228 15 8 15ZM6 13C4.34315 13 3 14.3431 3 16V18C3 19.6569 4.34315 21 6 21H8C9.65685 21 11 19.6569 11 18V16C11 14.3431 9.65685 13 8 13H6Z"
                      fill="#6F767E"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 5H16C15.4477 5 15 5.44772 15 6V8C15 8.55228 15.4477 9 16 9H18C18.5523 9 19 8.55228 19 8V6C19 5.44772 18.5523 5 18 5ZM16 3C14.3431 3 13 4.34315 13 6V8C13 9.65685 14.3431 11 16 11H18C19.6569 11 21 9.65685 21 8V6C21 4.34315 19.6569 3 18 3H16Z"
                      fill="#6F767E"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 15H16C15.4477 15 15 15.4477 15 16V18C15 18.5523 15.4477 19 16 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4477 18.5523 15 18 15ZM16 13C14.3431 13 13 14.3431 13 16V18C13 19.6569 14.3431 21 16 21H18C19.6569 21 21 19.6569 21 18V16C21 14.3431 19.6569 13 18 13H16Z"
                      fill="#6F767E"
                    />
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Main
                </Text>
              </Box>
            </NavLink>
          </Box>
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive ? " store " : ` store storeActive ${styles.active}`)
              }
              end
              to="/Admin/StoresHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                        fill="#000000"
                      />
                      <rect
                        fill="#FFFFFF"
                        x="13"
                        y="8"
                        width="3"
                        height="3"
                        rx="1"
                      />
                      <path
                        d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Store
                </Text>
              </Box>
            </NavLink>
          </Box>
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive
                  ? " category "
                  : ` category categoryActive ${styles.active}`)
              }
              end
              to="/Admin/CategoriesHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                  >
                    <g
                      stroke="none"
                      strokeWwidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M4,9.67471899 L10.880262,13.6470401 C10.9543486,13.689814 11.0320333,13.7207107 11.1111111,13.740321 L11.1111111,21.4444444 L4.49070127,17.526473 C4.18655139,17.3464765 4,17.0193034 4,16.6658832 L4,9.67471899 Z M20,9.56911707 L20,16.6658832 C20,17.0193034 19.8134486,17.3464765 19.5092987,17.526473 L12.8888889,21.4444444 L12.8888889,13.6728275 C12.9050191,13.6647696 12.9210067,13.6561758 12.9368301,13.6470401 L20,9.56911707 Z"
                        fill="#000000"
                      />
                      <path
                        d="M4.21611835,7.74669402 C4.30015839,7.64056877 4.40623188,7.55087574 4.5299008,7.48500698 L11.5299008,3.75665466 C11.8237589,3.60013944 12.1762411,3.60013944 12.4700992,3.75665466 L19.4700992,7.48500698 C19.5654307,7.53578262 19.6503066,7.60071528 19.7226939,7.67641889 L12.0479413,12.1074394 C11.9974761,12.1365754 11.9509488,12.1699127 11.9085461,12.2067543 C11.8661433,12.1699127 11.819616,12.1365754 11.7691509,12.1074394 L4.21611835,7.74669402 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Category
                </Text>
              </Box>
            </NavLink>
          </Box>
          {/* <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive
                  ? " subCategory "
                  : ` subCategory subCategoryActive ${styles.active}`)
              }
              end
              to="/Admin/SubCategoriesHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    fill="none"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" opacity="0" />
                      <path
                        d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Sub Category
                </Text>
              </Box>
            </NavLink>
          </Box> */}

          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive
                  ? " coupon "
                  : ` coupon couponActive ${styles.active}`)
              }
              end
              to="/Admin/CouponsHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M12.4644661,14.5355339 L9.46446609,14.5355339 C8.91218134,14.5355339 8.46446609,14.9832492 8.46446609,15.5355339 C8.46446609,16.0878187 8.91218134,16.5355339 9.46446609,16.5355339 L12.4644661,16.5355339 L12.4644661,17.5355339 C12.4644661,18.6401034 11.5690356,19.5355339 10.4644661,19.5355339 L6.46446609,19.5355339 C5.35989659,19.5355339 4.46446609,18.6401034 4.46446609,17.5355339 L4.46446609,13.5355339 C4.46446609,12.4309644 5.35989659,11.5355339 6.46446609,11.5355339 L10.4644661,11.5355339 C11.5690356,11.5355339 12.4644661,12.4309644 12.4644661,13.5355339 L12.4644661,14.5355339 Z"
                        fill="#000000"
                        opacity="0.3"
                        transform="translate(8.464466, 15.535534) rotate(-45.000000) translate(-8.464466, -15.535534) "
                      />
                      <path
                        d="M11.5355339,9.46446609 L14.5355339,9.46446609 C15.0878187,9.46446609 15.5355339,9.01675084 15.5355339,8.46446609 C15.5355339,7.91218134 15.0878187,7.46446609 14.5355339,7.46446609 L11.5355339,7.46446609 L11.5355339,6.46446609 C11.5355339,5.35989659 12.4309644,4.46446609 13.5355339,4.46446609 L17.5355339,4.46446609 C18.6401034,4.46446609 19.5355339,5.35989659 19.5355339,6.46446609 L19.5355339,10.4644661 C19.5355339,11.5690356 18.6401034,12.4644661 17.5355339,12.4644661 L13.5355339,12.4644661 C12.4309644,12.4644661 11.5355339,11.5690356 11.5355339,10.4644661 L11.5355339,9.46446609 Z"
                        fill="#000000"
                        transform="translate(15.535534, 8.464466) rotate(-45.000000) translate(-15.535534, -8.464466) "
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Coupon
                </Text>
              </Box>
            </NavLink>
          </Box>
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive
                  ? " banner "
                  : ` banner bannerActive ${styles.active}`)
              }
              end
              to="/Admin/BannersHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M4.5,6 L19.5,6 C20.8807119,6 22,6.97004971 22,8.16666667 L22,16.8333333 C22,18.0299503 20.8807119,19 19.5,19 L4.5,19 C3.11928813,19 2,18.0299503 2,16.8333333 L2,8.16666667 C2,6.97004971 3.11928813,6 4.5,6 Z M4,8 L4,17 L20,17 L20,8 L4,8 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <polygon
                        fill="#000000"
                        opacity="0.3"
                        points="4 8 4 17 20 17 20 8"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="20"
                        width="10"
                        height="1"
                        rx="0.5"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Banner
                </Text>
              </Box>
            </NavLink>
          </Box>
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive
                  ? " Coupons Report "
                  : ` coupon couponActive ${styles.active}`)
              }
              end
              to="/Admin/UsedCouponHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M4.5,6 L19.5,6 C20.8807119,6 22,6.97004971 22,8.16666667 L22,16.8333333 C22,18.0299503 20.8807119,19 19.5,19 L4.5,19 C3.11928813,19 2,18.0299503 2,16.8333333 L2,8.16666667 C2,6.97004971 3.11928813,6 4.5,6 Z M4,8 L4,17 L20,17 L20,8 L4,8 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <polygon
                        fill="#000000"
                        opacity="0.3"
                        points="4 8 4 17 20 17 20 8"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="7"
                        y="20"
                        width="10"
                        height="1"
                        rx="0.5"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Coupons Report
                </Text>
              </Box>
            </NavLink>
          </Box>
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive ? " types " : ` types typesActive ${styles.active}`)
              }
              end
              to="/Admin/TypesHistory"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    className={`${styles.iconPos}`}
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="5"
                        y="8"
                        width="2"
                        height="8"
                        rx="1"
                      />
                      <path
                        d="M6,21 C7.1045695,21 8,20.1045695 8,19 C8,17.8954305 7.1045695,17 6,17 C4.8954305,17 4,17.8954305 4,19 C4,20.1045695 4.8954305,21 6,21 Z M6,23 C3.790861,23 2,21.209139 2,19 C2,16.790861 3.790861,15 6,15 C8.209139,15 10,16.790861 10,19 C10,21.209139 8.209139,23 6,23 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="17"
                        y="8"
                        width="2"
                        height="8"
                        rx="1"
                      />
                      <path
                        d="M18,21 C19.1045695,21 20,20.1045695 20,19 C20,17.8954305 19.1045695,17 18,17 C16.8954305,17 16,17.8954305 16,19 C16,20.1045695 16.8954305,21 18,21 Z M18,23 C15.790861,23 14,21.209139 14,19 C14,16.790861 15.790861,15 18,15 C20.209139,15 22,16.790861 22,19 C22,21.209139 20.209139,23 18,23 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                      <path
                        d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                      <path
                        d="M18,7 C19.1045695,7 20,6.1045695 20,5 C20,3.8954305 19.1045695,3 18,3 C16.8954305,3 16,3.8954305 16,5 C16,6.1045695 16.8954305,7 18,7 Z M18,9 C15.790861,9 14,7.209139 14,5 C14,2.790861 15.790861,1 18,1 C20.209139,1 22,2.790861 22,5 C22,7.209139 20.209139,9 18,9 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  Types
                </Text>
              </Box>
            </NavLink>
          </Box>
          <Box my="5px">
            <NavLink
              onClick={(event) => {
                handleAccordBtn(event);
                //close side bar on render another component
                // handleSidebar();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
              className={({ isActive }) =>
                `${styles.nav_link}` +
                (!isActive ? " About " : ` about aboutActive ${styles.active}`)
              }
              end
              to="/Admin/About"
            >
              <Box
                p="12px"
                py="13px"
                ref={ref}
                className={`${styles.list_items}`}
              >
                <Box height="25px">
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    className={`${styles.iconPos}`}
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <rect
                        fill="#000000"
                        x="6"
                        y="11"
                        width="9"
                        height="2"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="6"
                        y="15"
                        width="5"
                        height="2"
                        rx="1"
                      />
                    </g>
                  </svg>
                </Box>

                <Text fontSize="15px" color="#1D1F22" pl="8px">
                  About Us
                </Text>
              </Box>
            </NavLink>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
