import React, { useRef } from "react";
import { Box, Text, Button, Image } from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import { MainButton } from "../button/MainButton";
import { useTranslation } from "react-i18next";

import styles from "./brandDetialsCard.module.scss";

export const BrandDetailsCard = ({
  title,
  icon,
  path,
  secondUrl,
  onCopiedOpen,
  code,
  storeUrl,
  setStoreUrlCopied,
  setCouponID,
  id,
  setStoreID,
  storeID,
  desc,
  storeName,
  setCouponCode,
}) => {
  const linkAnchor = useRef(null);
  const { t, i18n } = useTranslation();
  // const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const handleClickUrl = () => {
    setStoreID(storeID);
    setCouponID(id);
    /* await sleep(5000);
    let url = storeUrl;
    window.open(`${url}`, "_blank"); */

    setTimeout(() => {
      // console.log("linkAnchor.current", linkAnchor.current);
      // linkAnchor.current.click();
      let url = storeUrl;
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url;
      }
      // window.open(`${url}`, "_blank");

      let n = window.open(`${url}`, "_blank");
      // let n = window.open(`${url}`);
      // var popUp = window.open("https://www.google.com");
      // try {
      //   n.focus();
      // } catch (e) {
      //   alert(t("popupBlocked"));
      // }
      if (n == null) {
        alert(t("popupBlocked"));
        return true;
      }
      return false;
    }, 3000);
  };
  // console.log("storeUrl", storeUrl);
  return (
    <>
      <Box
        className={`${styles.center} ${styles.brand}`}
        display="flex"
        flexDirection="column"
      >
        <Box display="flex">
          {icon && (
            // <Box mr={marginRight} ml={marginLeft}>
            <Image paddingY={3} src={icon} alt="icon" />
            // </Box>
          )}
          <Box
            marginLeft={i18n.language === "en" ? 3 : 0}
            marginRight={i18n.language === "en" ? 0 : 3}
          >
            <Text
              fontWeight="bold"
              fontSize="18px"
              lineHeight="20px"
              marginTop={3}
              marginBottom={1}
            >
              {title}
            </Text>
            <Text
              fontWeight="300"
              fontSize="14px"
              lineHeight="15px"
              marginY={1}
            >
              {desc}
            </Text>
          </Box>
        </Box>

        <Box marginY={3} width="100%">
          <MainButton
            btnWidthFull={true}
            title={t("Show Coupon")}
            onPress={() => {
              window.dataLayer.push({
                event: "open-coupon-click",
              });
              onCopiedOpen();
              setCouponCode(`${code}`);
              setStoreUrlCopied(`${storeUrl}`);
              navigator.clipboard.writeText(`${code}`);
              handleClickUrl();
            }}
            w100={true}
          />
          {/* <Link ref={linkAnchor} to={`${storeUrl}`} target="_blank" /> */}
        </Box>

        <Box width="100%" display="flex" justifyContent="center">
          <NavLink
            className={`${styles.center} ${styles.brandLink}`}
            to={secondUrl}
            onClick={() =>
              window.dataLayer.push({
                event: "view-store-coupon-click",
              })
            }
          >
            {t("View all")} {storeName} {t("Offers")}
          </NavLink>
        </Box>
      </Box>
    </>
  );
};
