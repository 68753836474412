import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost, AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//Stores
export const GetStoresAPI = async (page = 1) => {
  // const URL = `/banners`;
  const URL = `/stores?page=${page}&limit=10`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetStoresFullAPI = async () => {
  // const URL = `/banners`;
  const URL = `/stores?limit=100000`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetTypesAPI = async () => {
  // const URL = `/banners`;
  const URL = `/types`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetStoreByIdAPI = async (id) => {
  const URL = `/stores/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddStoreAPI = async (payload) => {
  const URL = "/stores";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateStoreAPI = async (payload) => {
  // const URL = `/addStore/${id}`;
  console.log("payload", payload);
  const URL = `/stores`;
  try {
    const response = await AxiosClientMultipart.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeactiveActiveStoreByIdAPI = async (id, active = 0) => {
  const URL = `/stores/${id}?number=${active}`;
  try {
    const response = await AxiosClientPost.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteStoreByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
