import * as Yup from "yup";

export const validationStoreForm = Yup.object().shape({
  name_ar: Yup.string().required("This field is required"),
  name_en: Yup.string().required("This field is required"),
  description_en: Yup.string().required("This field is required"),
  description_ar: Yup.string().required("This field is required"),
  url: Yup.string().required("This field is required"),
  store_type_id: Yup.string().required("This field is required"),
  category_id: Yup.string().required("This field is required"),
  // sub_category_id: Yup.string().required("This field is required"),
  image: Yup.string().required("This field is required"),
  seo_en: Yup.string().required("This field is required"),
  seo_ar: Yup.string().required("This field is required"),
});
