import react, { useState, useEffect, useCallback, useRef } from "react";

import { GetCategoriesAPI } from "../../api/landing/CategoryAPI";
import {
  Box,
  Text,
  Image,
  InputRightElement,
  Input,
  InputGroup,
  Button,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Center,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { MainTitle } from "../../components/landing/mainTitle/MainTitle";

import categ from "../../assets/images/categ.png";
import logo from "../../assets/images/logo.png";
import { CategoriesCard } from "../../components/landing/categoriesCard/CategoriesCard";
import { MainBox } from "../../components/landing/mainBox/MainBox";
import { ModalAlert2 } from "../../components/landing//modalAlert/ModalAlert2";
import checked from "../../assets/images/success.png";

export const AllCategories = () => {
  const { t, i18n } = useTranslation();
  const baseUrl = "/" + i18n.language;
  // ${baseUrl}
  const [errorMessage, setErrorMessage] = useState(`${t("Please try again.")}`);
  const [generalData, setGeneralData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [generalData2, setGeneralData2] = useState([]);
  const [isLoading2, setIsLoading2] = useState(true);
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetCategoriesAPI()
      .then((res) => {
        setGeneralData(res.categories.rows);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        // console.log(err);
        onErrorOpen();
        setErrorMessage(err?.response?.data?.message || t("Please try again."));
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <div>
      <Helmet>
        <title>All Categories</title>
        <meta name="description" content="AllCategories" />
      </Helmet>

      <Box marginTop="15px">
        <MainTitle px={16} pt={8} pb={8} title={t("All Categories")} />
      </Box>
      <Box>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink color="#706F6F" href="/">
              {t("Home")}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink color="#022E5B" href="/AllCategories">
              {t("All Categories")}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>

      <Box display="flex" flexWrap="wrap" marginTop="20px" gap="2%">
        {isLoading ? (
          <>
            <MainBox>
              <Box paddingTop={12} paddingBottom={12}>
                <Center>
                  <Spinner />
                </Center>
              </Box>
            </MainBox>
          </>
        ) : (
          <>
            {generalData.length > 0 ? (
              <>
                {generalData.map((el) => {
                  /* let categories = el.category_subs.map((item) => {
                    return {
                      titleEn: item.sub_name_en,
                      titleAr: item.sub_name_ar,
                      path: `/Category/${item.sub_id}`,
                    };
                  }); */

                  return (
                    <CategoriesCard
                      path={`${baseUrl}/Category/${el.id}`}
                      secondUrl="/"
                      icon={`https://www.glocoupon.com/api2/${el.image}`}
                      title={i18n.language == "en" ? el.name_en : el.name_ar}
                      // categories={categories}
                    />
                  );
                })}
              </>
            ) : (
              <Box
                textAlign="center"
                paddingY={14}
                borderRadius="20px"
                background="white"
                fontSize="24px"
                width="100%"
              >
                {t("There is no coupons to show")}
              </Box>
            )}
          </>
        )}
      </Box>

      {/* <Box
        background="white"
        position="absolute"
        left="0"
        right="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        paddingX="55px"
        paddingBottom={16}
        className="section_bef-footer "
      >
        <Image src={logo} alt="logo" width="200px" paddingY={12} />

        <Box
          marginTop={6}
          borderRadius="25px"
          background="#022F59"
          display="flex"
          padding="20px"
          width="100%"
        >
          <Box width="50%">
            <Text color="white" fontSize="25px">
              {t("contact1")}
            </Text>
            <Text color="#6AEFAB" fontSize="25px">
              {t("contact2")}
            </Text>
          </Box>
          <Box width="50%">
            <InputGroup
              size="md"
              height="100%"
              background="white"
              borderRadius="10px"
            >
              <Input
                pr="4.5rem"
                // paddingLeft="65px"
                // variant="filled"
                placeholder=""
                height="100%"
                borderTopRightRadius="10px"
                borderBottomRightRadius="10px"
              />
              <InputRightElement
                width="4.5rem"
                background="#6AEFAB"
                borderTopRightRadius="10px"
                borderBottomRightRadius="10px"
                height="100%"
                paddingX={16}
                position="relative"
              >
                <Button
                  // h="1.75rem"
                  size="sm"
                  background="#6AEFAB" onClick={handleClick}
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  height="100%"
                >
                  <Text>{t("Subscribe")} </Text>
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </Box>
      </Box>
      <Box className="section_bef-footer2" marginTop="350px"></Box> */}
      <ModalAlert2
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={t("Error")}
        desc={errorMessage}
        btnWidthFull={true}
        icon={checked}
        hasDesc={true}
        isError={true}
      />
    </div>
  );
};
