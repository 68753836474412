import { Box, Text, Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import styles from "./button.module.scss";

export const MainButton = ({
  title,
  url,
  btnStyle,
  btnWidthFull,
  fontColor,
  reverseRow,
  onPress,
  icon,
  disabledBtn,
  marginRight = "0",
  marginLeft = "0",
  type = "button",
  border,
  borderColor,
  btnHeight = "unset",
  navLink,
  path,
  fSize = "md",
  mt = "",
  className,
}) => {
  let btnClassname;

  switch (btnStyle) {
    case "gray":
      btnClassname = `${styles.btn_gray}`;
      break;
    case "transparent":
      btnClassname = `${styles.btn_transparent}`;
      break;
    case "transparent2":
      btnClassname = `${styles.btn_transparent2}`;
      break;
    case "navyBlue":
      btnClassname = `${styles.btn_navyBlue}`;
      break;
    case "navyBlue2":
      btnClassname = `${styles.btn_navyBlue2}`;
      break;
    case "green":
      btnClassname = `${styles.btn_green}`;
      break;
    case "gray_border":
      btnClassname = `${styles.btn_gray_border}`;
      break;
    case "secondary":
      btnClassname = `${styles.btn_secondary}`;
      break;
    default:
      btnClassname = `${styles.btn_primary}`;
  }

  switch (fontColor) {
    case "dark":
      btnClassname += ` ${styles.color_dark} `;
      break;
    case "light":
      btnClassname += ` ${styles.color_light} `;
      break;
    case "danger":
      btnClassname += ` ${styles.color_danger} `;
      break;
    case "assets":
      btnClassname += ` ${styles.color_assets} `;
      break;
  }

  if (mt === "mt7") {
    btnClassname += ` ${styles.mt7} `;
  }

  btnClassname += btnWidthFull ? ` ${styles.w_100} ` : "";

  btnClassname +=
    border && borderColor === "gray" ? ` ${styles.border_gray} ` : "";

  if (reverseRow === true) {
    btnClassname += ` ${styles.flex_row_reverse} `;
  }
  let fontSizeClassname;

  switch (fSize) {
    case "md":
      fontSizeClassname = `title_sm ${styles.pointerNone}`;
      break;
    case "sm":
      fontSizeClassname = `title_sm2 ${styles.pointerNone}`;
      break;
    case "sm_x2":
      fontSizeClassname = `title_sm_x2 ${styles.pointerNone}`;
      break;
  }
  // if (btnStyle === "gray") {
  //   btnClassname = `${styles.btn_gray}`;
  // } else if (btnStyle === "transparent") {
  //   btnClassname = `${styles.btn_transparent}`;
  // } else if (btnStyle === "navyBlue") {
  //   btnClassname = `${styles.btn_navyBlue}`;
  // }
  // else if (btnStyle === "navyBlue2") {
  //   btnClassname = `${styles.btn_navyBlue2}`;

  // }
  // else if (btnStyle === "green") {
  //   btnClassname = `${styles.btn_green}`
  // }
  // else if (btnStyle === "green_border") {
  //   btnClassname = `${styles.btn_green_border}`
  // }
  // else {
  //   btnClassname = `${styles.btn_primary}`;
  // }

  // if (fontColor === "dark") {
  //   btnClassname += ` ${styles.color_dark} `;
  // } else if (fontColor === "light") {
  //   btnClassname += ` ${styles.color_light} `;
  // } else if (fontColor === "danger") {
  //   btnClassname += ` ${styles.color_danger} `;
  // }

  // if (fSize === "md") {
  //   fontSizeClassname = `title_sm ${styles.pointerNone}`;
  // } else if (fSize === "sm") {
  //   fontSizeClassname = `title_sm2 ${styles.pointerNone}`;
  // } else if (fSize === "sm_x2") {
  //   fontSizeClassname = `title_sm_x2 ${styles.pointerNone}`;
  // }

  const isNavLink = () => {
    if (navLink) {
      return (
        <NavLink
          className={`${styles.center} ${disabledBtn && styles.pointerNone}`}
          to={path}
        >
          <Button
            className={`${btnClassname} ${mt} b${btnHeight} `}
            disabled={disabledBtn}
            type={type}
            py={8}
            height={btnHeight}
          >
            <Box
              display="flex"
              alignItems="center"
              className={`${
                reverseRow === true ? styles.flex_row_reverse : ""
              }`}
            >
              {icon && (
                <Box mr={marginRight} ml={marginLeft}>
                  <img src={icon} alt="icon" />
                </Box>
              )}

              <Text className={fontSizeClassname}>{title}</Text>
            </Box>
          </Button>
        </NavLink>
      );
    } else {
      return (
        <Button
          className={`${btnClassname} b_${btnHeight}  ${className}`}
          onClick={onPress}
          disabled={disabledBtn}
          type={type}
          py={8}
          height={btnHeight}
        >
          {icon && (
            <Box
              className={styles.pointerNone}
              mr={marginRight}
              ml={marginLeft}
            >
              <img src={icon} alt="icon" />
            </Box>
          )}

          <Text className={fontSizeClassname}>{title}</Text>
        </Button>
      );
    }
  };

  return <>{isNavLink()}</>;
};
