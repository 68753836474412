import React, { forwardRef } from "react";

import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Text,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
} from "@chakra-ui/react";

import styles from "./header.module.scss";

import logoLG from "../../assets/images/logo.png";
import arrowDown from "../../assets/images/icons/Cheveron.svg";

export const Header = forwardRef((props, ref) => {
  return (
    <nav
      className={`${styles.navbar} ${!props.isOpen && styles.sidebarOpen}`}
      ref={ref}
    >
      <HStack spacing="auto" width="100%" flexDirection="row">
        <Box display="flex" alignItems="center">
          <NavLink to={"/Admin"}>
            <Image src={logoLG} className={styles.logo} alt="logo" />
          </NavLink>
        </Box>
        <Box display="flex" marginLeft="auto">
          <Box className="btn_profile">
            <Menu autoSelect={false}>
              <MenuButton
                as={Button}
                bg="transparent"
                rightIcon={<img src={arrowDown} alt="arrow icon" />}
              >
                <Text color="#002A5C">{props.user.username}</Text>
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    Cookies.remove("token");
                    window.location.replace("/Admin");
                    // window.location.reload(false);
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      </HStack>
    </nav>
  );
});
