import react, { useState, useEffect, useCallback, useRef } from "react";

import { SearchCouponeNameAPI } from "../../api/landing/CouponAPI";
import { Box, Center, Spinner, useDisclosure } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { MainTitle } from "../../components/landing/mainTitle/MainTitle";
import { MainBox } from "../../components/landing/mainBox/MainBox";
import { ModalAlert } from "../../components/landing/modalAlert/ModalAlert";
import { ModalAlert2 } from "../../components/landing//modalAlert/ModalAlert2";
import { useLocation } from "react-router-dom";
import { BrandDetailsCard } from "../../components/landing/brandDetailsCard/BrandDetailsCard";
import checked from "../../assets/images/success.png";

export const Search = () => {
  const {
    isOpen: isCopiedOpen,
    onOpen: onCopiedOpen,
    onClose: onCopiedClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const { t, i18n } = useTranslation();
  const baseUrl = "/" + i18n.language;
  const [isLoading, setIsLoading] = useState(true);
  const [storeUrlCopied, setStoreUrlCopied] = useState("");
  const [couponID, setCouponID] = useState("");

  const [storeID, setStoreID] = useState("");
  const [couponsData, setCouponsData] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  let location = useLocation();

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);
    let payload;
    if (i18n.language == "en") {
      payload = {
        nameEn: location.state.search,
        nameAr: "",
      };
    } else {
      payload = {
        nameAr: location.state.search,
        nameEn: "",
      };
    }
    SearchCouponeNameAPI(payload)
      .then((res) => {
        setCouponsData(res.couponMap);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        // console.log(err);
        onErrorOpen();
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <div>
      <Helmet>
        <title>Search</title>
        <meta name="description" content="Search" />
      </Helmet>

      <Box>
        <MainTitle
          px={16}
          pt={8}
          pb={8}
          title={`${t("Search about:")} ${location.state.search}`}
        />
      </Box>
      <Box display="flex" flexWrap="wrap" marginTop="20px" gap="2%">
        {isLoading ? (
          <>
            <MainBox>
              <Box paddingTop={12} paddingBottom={12}>
                <Center>
                  <Spinner />
                </Center>
              </Box>
            </MainBox>
          </>
        ) : (
          <>
            {couponsData.length > 0 ? (
              <>
                {couponsData?.map((el, ind) => {
                  return (
                    <BrandDetailsCard
                      path={`${baseUrl}/coupon/${el.id}`}
                      secondUrl={`store/${el.id}`}
                      onCopiedOpen={onCopiedOpen}
                      storeUrl={el.store_url}
                      code={el?.code}
                      setStoreUrlCopied={setStoreUrlCopied}
                      icon={`https://www.glocoupon.com/api2/${el.store_image}`}
                      title={`${
                        i18n.language == "en" ? el.name_en : el.name_ar
                      }`}
                      setCouponID={setCouponID}
                      id={el.id}
                      storeID={el.store_id}
                      setStoreID={setStoreID}
                      setCouponCode={setCouponCode}
                    />
                  );
                })}
              </>
            ) : (
              <Box
                textAlign="center"
                paddingY={14}
                borderRadius="20px"
                background="white"
                fontSize="24px"
                width="100%"
              >
                {t("There is no coupons to show")}
              </Box>
            )}
          </>
        )}
      </Box>

      {storeUrlCopied && (
        <ModalAlert
          isOpen={isCopiedOpen}
          onClose={onCopiedClose}
          title={t("Code Copied")}
          desc={t("Paste This Coupon Code Checkout")}
          linkText={t("Go to the store")}
          btnWidthFull={true}
          icon={checked}
          disableClose={true}
          path={`${baseUrl}/Store/${storeID}`}
          hasDesc={true}
          closeOnOverlayClicked={true}
          setStoreUrlCopied={setStoreUrlCopied}
          couponID={couponID}
          couponCode={couponCode}
        />
      )}
      <ModalAlert2
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={t("Error")}
        desc={t("Please try again.")}
        btnWidthFull={true}
        icon={checked}
        path={`${baseUrl}/Store/${storeID}`}
        hasDesc={true}
        isError={true}
      />
    </div>
  );
};
