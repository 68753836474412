import * as Yup from "yup";

export const validationCouponForm = Yup.object().shape({
  name_ar: Yup.string().required("This field is required"),
  name_en: Yup.string().required("This field is required"),
  code: Yup.string().required("This field is required"),
  store_id: Yup.string().required("This field is required"),
  category_id: Yup.string().required("This field is required"),
  // sub_category_id: Yup.string().required("This field is required"),
});
