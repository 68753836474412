import React, { useState, useEffect, memo } from "react";
import { Box, Text, Center, Button, Image, filter } from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import styles from "../../components/forms.module.scss";
import tableRightArrow from "../../assets/images/icons/tableRightArrow.svg";
// import searchIcon from "../../assets/images/icons/search.svg";
// import plus from "../../assets/images/icons/plus.svg";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import plus from "../../assets/images/iconsSvg/plus.svg";
import { NavLink } from "react-router-dom";

export const TablePagination2 = memo(function TablePagination2({
  data,
  columns,
  onPressBtn,
  isSearchable,
  title,
  hideBox,
  hasDelete,
  pageSize,
  totalRows,
  handleOnPageChange,
  setCurrPagData,
  currPagData,
  onCreateModalOpen,
  searchTerm,
  setSearchTerm,
  path,
  plus,
  buttonTitle,
  haveCreateButton,
  exportExcelBtn,
  dataSetExcel,
  type,
  filterObj = "{}",
}) {
  const [tableData, setTableData] = useState(data);
  //const [search, setSearch] = useState(tableData);
  let search = [];
  const [pages, setPages] = useState([]);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [pageToShow, setPageToShow] = useState(1);
  const [leftLeftSide, setLeftLeftSide] = useState(0);
  const [leftRightSide, setLeftRightSide] = useState(3);
  const temp = [];
  const [isTextDisabled, setIsTextDisabled] = useState(true);
  // const [searchText, setSearchText] = React.useState("");

  let totalRowsVal = Math.ceil(totalRows / pageSize);
  const handlePageChange = (event, page) => {
    setPageToShow(page);
    setCurrPagData({ ...currPagData, page: page });

    if (!!filterObj && JSON.stringify(filterObj) !== "{}") {
      handleOnPageChange(page, filterObj);
    } else {
      handleOnPageChange(page /* , currPagData.sort, currPagData.sortOrder */);
    }
  };
  const handleNext = () => {
    if (pageToShow !== totalRowsVal) {
      /* handleOnPageChange(
        pageToShow + 1
        // currPagData.sort,
        // currPagData.sortOrder
      ); */
      if (!!filterObj && JSON.stringify(filterObj) !== "{}") {
        handleOnPageChange(pageToShow + 1, filterObj);
      } else {
        handleOnPageChange(pageToShow + 1);
      }
      setPageToShow(pageToShow + 1);
      setCurrPagData({ ...currPagData, page: pageToShow + 1 });
    }
  };
  const handlePrev = () => {
    if (pageToShow !== 1) {
      /* handleOnPageChange(
        pageToShow - 1
        // currPagData.sort,
        // currPagData.sortOrder
      ); */
      if (!!filterObj && JSON.stringify(filterObj) !== "{}") {
        handleOnPageChange(pageToShow - 1, filterObj);
      } else {
        handleOnPageChange(pageToShow - 1);
      }
      setPageToShow(pageToShow - 1);
      setCurrPagData({ ...currPagData, page: pageToShow - 1 });
    }
  };

  //filter data
  const handleSearchChange = (e) => {
    //if (tableData.length === 0) return;
    if (data.length === 0) return;
    //console.log("eeeeee", e, data);
    //setSearchText(e.target.value);
    setSearchTerm(e.target.value);
    /* const value = e.target.value;

    const newFilter = [...tableData].filter(
      (val) =>
        val.assetName.toLowerCase().includes(value.toLowerCase()) ||
        val.region.toLowerCase().includes(value.toLowerCase()) ||
        val.location.toLowerCase().includes(value.toLowerCase()) ||
        val.assignedTo.toLowerCase().includes(value.toLowerCase())
    );

    if (newFilter.length > 0) {
      setPageToShow(1);
      setCurrPagData({ ...currPagData, page: 1 });
      setLeftLeftSide(0);

      if (newFilter.length / pageSize !== pages.length) {
        setLeftRightSide(3);
      } else {
        setLeftRightSide(3);
      }
      // setSearch(newFilter);
      totalRowsVal = Math.ceil(newFilter.length / pageSize);
    }

    if (newFilter.length === 0) {
      setLeftLeftSide(0);
      setLeftRightSide(0);
      // setSearch(newFilter);
    }

    if (
      totalRowsVal - 1 === leftRightSide ||
      newFilter.length === 0 ||
      Math.ceil(newFilter.length / pageSize) === leftRightSide + 1
    ) {
      setIsTextDisabled(false);
    } else {
      if (totalRowsVal !== 2) {
        setIsTextDisabled(true);
      } else {
        setIsTextDisabled(false);
      }
    }

    if (e.target.value === "") {
      setLeftLeftSide(0);
      setLeftRightSide(3);
      if (totalRowsVal !== 2) {
        setIsTextDisabled(true);
      } else {
        setIsTextDisabled(false);
      }
    } */
  };

  useEffect(() => {
    for (var i = 0; i < totalRowsVal; i++) {
      temp[i] = totalRowsVal - i;
    }
    const reversed = temp.slice().reverse();

    setPages(reversed);

    if (totalRowsVal - 1 <= leftRightSide) {
      setIsTextDisabled(false);
    }
  }, []);
  useEffect(() => {
    if (pageToShow === leftLeftSide + 1 && pageToShow !== 1) {
      setLeftLeftSide(leftLeftSide - 1);
      setLeftRightSide(leftRightSide - 1);

      if (totalRowsVal - 1 === leftRightSide) {
        setIsTextDisabled(true);
      }
    }
    if (pageToShow === leftRightSide && pageToShow !== totalRowsVal - 1) {
      setLeftRightSide(leftRightSide + 1);
      setLeftLeftSide(leftLeftSide + 1);
      if (totalRowsVal - 1 === leftRightSide + 1) {
        setIsTextDisabled(false);
      }
    }
    if (pageToShow === totalRowsVal) {
      setLeftRightSide(totalRowsVal - 1);
      // setLeftLeftSide(totalRowsVal - 4);
      setLeftLeftSide(0);
      setIsTextDisabled(false);
    }

    if (pageToShow === 1) setDisablePrev(true);
    else setDisablePrev(false);

    if (pageToShow === totalRowsVal) setDisableNext(true);
    else setDisableNext(false);

    if (totalRowsVal === 0) {
      setDisableNext(true);
      setDisablePrev(true);
    }
  }, [handleNext, handlePrev]);

  useEffect(() => {
    /* setTableData(tableData); */
    setTableData((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(tableData)) {
        return prev;
      } else {
        return tableData;
      }
    });
  }, [tableData]);
  useEffect(() => {
    // console.log("searchTerm", searchTerm);
    //const value = e.target.value;
    //searchText
    const getData = setTimeout(() => {
      handleOnPageChange(
        pageToShow
        // currPagData.sort,
        // currPagData.sortOrder,
        // searchTerm
      );
      /* fetch("https://jsonplaceholder.typicode.com/posts/1")
        .then((response) => response.json())
        .then((json) => console.log(json)); */
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchTerm]);
  // console.log(
  //   "leftLeftSide, leftRightSide",
  //   leftLeftSide,
  //   leftRightSide,
  //   pageToShow,
  //   totalRowsVal
  // );
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <MainTitle title={title} />
        <Box display="flex" maxWidth="50%" width="50%">
          <Box maxWidth="80%" width="80%" flex="1"></Box>

          {haveCreateButton && (
            <Box ml={3}>
              <NavLink to={path}>
                <MainButton
                  title={buttonTitle}
                  icon={plus}
                  btnHeight="48px"
                  /* onPress={() => {
                  onCreateModalOpen();
                }} */
                />
              </NavLink>
            </Box>
          )}
        </Box>
        {/* {isSearchable && (
          <Box display="flex" maxWidth="50%" width="50%">
            <Box maxWidth="80%" width="80%" flex="1">
              <div className={styles.form_control}>
                <div
                  className={`${styles.form_control_input} ${styles.form_control_input_pl}`}
                >
                  <input
                    name="search"
                    placeholder="Search"
                    type="text"
                    id="search"
                    value={searchTerm}
                    //onChange={handleSearchChange}
                    onChange={handleSearchChange}
                  />
                  <div className="iconic_left_input">
                    <img src={searchIcon} alt="icon" />
                  </div>
                </div>
              </div>
            </Box>
            <Box ml={3}>
              <MainButton
                title="Create new asset"
                icon={plus}
                btnHeight="48px"
                onPress={() => {
                  onCreateModalOpen();
                }}
              />
            </Box>
          </Box>
          )} */}
      </Box>

      <Box pb={8} mr={3} mb={8} width="100%">
        {hideBox ? (
          <Box className="table_content" pt={3} pb={2} mb={5}>
            <DataTable columns={columns} data={data} defaultSortAsc={false} />
          </Box>
        ) : (
          <MainBox>
            <Box className="table_content" pt={3} pb={2}>
              <DataTable
                columns={columns}
                data={[...data]}
                defaultSortAsc={false}
              />
            </Box>
          </MainBox>
        )}

        {totalRows > 10 && (
          <Center mt="-3">
            <Button
              mx="1"
              border="1px solid #E2E2EA"
              bg="#FFFFFF"
              px="2.5 !important"
              py="3.5"
              size={{ base: "xs", md: "xs", lg: "xs" }}
              onClick={handlePrev}
              isDisabled={disablePrev}
            >
              <Box>
                <Image className="table_prev" src={tableRightArrow}></Image>
              </Box>
            </Button>

            {/* {pages.slice(leftLeftSide, leftRightSide).map((page) => { */}
            {pages.slice(leftLeftSide, totalRowsVal).map((page) => {
              if (
                (page !== totalRowsVal && page < totalRowsVal) ||
                (page < 5 && page !== totalRowsVal)
              ) {
                return (
                  <Button
                    key={page}
                    page={page}
                    color="#6F767E"
                    bg="transparent"
                    size="xs"
                    mx="1"
                    fontSize="15"
                    borderRadius="lg"
                    isActive={pageToShow === page}
                    _active={{ color: "black" }}
                    onClick={(event) => handlePageChange(event, page)}
                  >
                    {page}
                  </Button>
                );
              }
              return null;
            })}

            {isTextDisabled && <Text fontSize="12">...</Text>}

            {pages.slice(totalRowsVal - 1, totalRowsVal).map((page) => (
              <Button
                key={page}
                page={page}
                color="#6F767E"
                mx="1"
                bg="transparent"
                size="xs"
                fontSize="15"
                borderRadius="lg"
                isActive={pageToShow === page}
                _active={{ color: "black" }}
                onClick={(event) => handlePageChange(event, page)}
              >
                {page}
              </Button>
            ))}

            <Button
              mx="1"
              border="1px solid #E2E2EA"
              bg="#FFFFFF"
              px="2.5 !important"
              py="3.5"
              size={{ base: "xs", md: "xs", lg: "xs" }}
              onClick={handleNext}
              isDisabled={disableNext}
            >
              <Box>
                <Image src={tableRightArrow}></Image>
              </Box>
            </Button>
          </Center>
        )}
      </Box>
    </>
  );
});
