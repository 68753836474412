import React, { useRef, useState } from "react";

import { Route, Routes } from "react-router-dom";

import styles from "./main.module.scss";

import { Header } from "../header";
import { DashboardHome } from "../../pages/DashboardHome";
import { StoresHistory } from "../../pages/stores/StoresHistory";
import { UsedCouponHistory } from "../../pages/usedCoupons/usedCouponHistory";
import { Store } from "../../pages/stores/Store";
import { CouponsHistory } from "../../pages/coupons/CouponsHistory";
import { Coupon } from "../../pages/coupons/Coupon";
import { BannersHistory } from "../../pages/banners/BannersHistory";
import { Banner } from "../../pages/banners/Banner";
import { TypesHistory } from "../../pages/types/TypesHistory";
import { Types } from "../../pages/types/Types";
import { CategoriesHistory } from "../../pages/categories/CategoriesHistory";
import { Category } from "../../pages/categories/Category";
import { About } from "../../pages/about/About";
/* import { SubCategoriesHistory } from "../../pages/subCategories/SubCategoriesHistory";
import { SubCategory } from "../../pages/subCategories/SubCategory"; */

export const Main = ({ user, isOpen }) => {
  const navbarRef = useRef(null);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 100) {
      navbarRef.current.classList.add(`${styles.navbar_scroll}`);
    } else {
      navbarRef.current.classList.remove(`${styles.navbar_scroll}`);
    }
  };

  const renderRoutes = () => {
    let userRole = "admin";

    switch (userRole) {
      case "admin":
        return (
          <Routes>
            <Route path="/Admin/" element={<DashboardHome />} />
            <Route path="/Admin/StoresHistory" element={<StoresHistory />} />
            <Route path="/Admin/Store" element={<Store />} />
            <Route path="/Admin/Store/:id" element={<Store />} />
            <Route path="/Admin/CouponsHistory" element={<CouponsHistory />} />
            <Route path="/Admin/Coupon" element={<Coupon />} />
            <Route path="/Admin/Coupon/:id" element={<Coupon />} />
            <Route path="/Admin/BannersHistory" element={<BannersHistory />} />
            <Route path="/Admin/Banner" element={<Banner />} />
            <Route path="/Admin/Banner/:id" element={<Banner />} />
            <Route path="/Admin/TypesHistory" element={<TypesHistory />} />
            <Route path="/Admin/Types" element={<Types />} />
            <Route path="/Admin/Types/:id" element={<Types />} />
            <Route path="/Admin/About" element={<About />} />
            <Route
              path="/Admin/CategoriesHistory"
              element={<CategoriesHistory />}
            />
            <Route path="/Admin/Category" element={<Category />} />
            <Route path="/Admin/Category/:id" element={<Category />} />
            {/*  <Route
              path="/Admin/SubCategoriesHistory"
              element={<SubCategoriesHistory />}
            />
            <Route path="/Admin/SubCategory" element={<SubCategory />} />
            <Route path="/Admin/SubCategory/:id" element={<SubCategory />} /> */}
            <Route
              path="/Admin/UsedCouponHistory"
              element={<UsedCouponHistory />}
            />
          </Routes>
        );
      default:
        return (
          <Routes>
            <Route path="/Admin/" element={<DashboardHome />} />
          </Routes>
        );
    }
  };

  return (
    <div id="main_content" className={styles.content} onScroll={handleScroll}>
      <Header ref={navbarRef} user={user} isOpen={isOpen} />
      <div className={styles.container}>{renderRoutes()}</div>
    </div>
  );
};
