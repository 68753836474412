import React, { useState, useEffect } from "react";

import {
  GetStoreByIdAPI,
  UpdateStoreAPI,
  AddStoreAPI,
  GetTypesAPI,
} from "../../api/StoreAPI";
import { GetCategoriesFullAPI } from "../../api/CategoryAPI";
// import { GetSubCategoriesAPI } from "../../api/SubCategoryAPI";

import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../utils/General";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { StoreForm } from "./StoreForm";
import { validationStoreForm } from "./validationStoreForm";

import checked from "../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const Store = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dropdownObj, setDropdownObj] = useState([]);

  let submitModalTitle = id
    ? "Store is successfully updated."
    : "Store is successfully created.";

  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        // ...formikValue,
        name_ar: formikValue.name_ar,
        name_en: formikValue.name_en,
        description_en: formikValue.description_en,
        description_ar: formikValue.description_ar,
        seo_en: formikValue.seo_en,
        seo_ar: formikValue.seo_ar,
        url: formikValue.url,
        category_id: formikValue.category_id,
        // sub_category_id: formikValue.sub_category_id,
        image: formikValue.image,
        store_type_id: formikValue.store_type_id,
        store_id: id,
      };
      console.log("submitValues", submitValues);
      UpdateStoreAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        // ...formikValue,
        name_ar: formikValue.name_ar,
        name_en: formikValue.name_en,
        description_en: formikValue.description_en,
        description_ar: formikValue.description_ar,
        seo_en: formikValue.seo_en,
        seo_ar: formikValue.seo_ar,
        url: formikValue.url,
        store_type_id: formikValue.store_type_id,
        category_id: formikValue.category_id,
        // sub_category_id: formikValue.sub_category_id,
        image: formikValue.image,
      };
      AddStoreAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  const getDropdowns = () => {
    let getCategories = new Promise((resolve, reject) => {
      GetCategoriesFullAPI(1)
        .then((res) => {
          if (res.categories.rows.length > 0) {
            let catOptions = [];
            res.categories.rows.forEach((item) => {
              catOptions.push({
                value: item.id,
                label: item.name_en,
              });
            });

            resolve(catOptions);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("Something went wrong");
        });
    });
    /* let getSubCat = new Promise((resolve, reject) => {
      GetSubCategoriesAPI()
        .then((res) => {
          if (res.subcategories.length > 0) {
            let arr = [];
            res.subcategories.forEach((item) => {
              arr.push({
                value: item.id,
                label: item.name_en,
              });
            });

            resolve(arr);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("Something went wrong");
        });
    }); */
    let getType = new Promise((resolve, reject) => {
      GetTypesAPI()
        .then((res) => {
          if (res.types.length > 0) {
            let arr = [];
            res.types.forEach((item) => {
              arr.push({
                value: item.id,
                label: item.type_en,
              });
            });

            resolve(arr);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          // setErrorMessage("Something went wrong");
        });
    });

    return Promise.all([getCategories, /* getSubCat, */ getType]).then(
      (values) => {
        setDropdownObj({
          category: values[0],
          // subCat: values[1],
          type: values[1],
        });
        return {
          category: values[0],
          // subCat: values[1],
          type: values[1],
        };
      }
    );
  };

  useEffect(() => {
    getDropdowns().then((data) => {
      if (!!id) {
        setErrorMessage("");
        GetStoreByIdAPI(id)
          .then((res) => {
            setInitFormVal({
              name_ar: res.store.name_ar,
              name_en: res.store.name_en,
              description_en: res.store.description_en,
              description_ar: res.store.description_ar,
              url: res.store.url,
              store_type_id: res.store.store_type_id,
              category_id: res.store.category_id,
              seo_en: res.store.seo_en,
              seo_ar: res.store.seo_ar,
              // sub_category_id: res.store.sub_category_id,
              image: res.store.image,
            });
            setGeneralData(res.store);
          })
          .catch((err) => {
            setErrorMessage("Something went wrong");
            onErrorOpen();
          });
      } else {
        setInitFormVal({
          name_en: "",
          name_ar: "",
          description_en: "",
          description_ar: "",
          url: "",
          store_type_id: "",
          seo_en: "",
          seo_ar: "",
          category_id: "",
          // sub_category_id: "",
          image: "",
        });
      }
    });
  }, []);

  return (
    <div className="Assumptions">
      <>
        <MainBox px={0}>
          {id === undefined ? (
            <MainTitle title="Create Store" bBottom={true} px={7} />
          ) : (
            <MainTitle title="Edit Store" bBottom={true} px={7} />
          )}
          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationStoreForm}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <StoreForm
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    dropdownObj={dropdownObj}
                    setDropdownObj={setDropdownObj}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/Admin/StoresHistory`}
        />
      </>
    </div>
  );
};
