import axiosClient from "./index";
import Cookies from "js-cookie";

export const SigninAPI = async (payload) => {
  const URL = "/login";
  try {
    const response = await axiosClient.post(URL, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     "Access-Control-Allow-Credentials": true,
      //   },
      ...payload,
      /* params: {
        token: Cookies.get("token"),
      }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
