import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

import { Text, Box, border, color } from "@chakra-ui/react";
import styles from "../forms.module.scss";

import arrow from "../../assets/images/icons/Cheveron.svg";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#EFEFEF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        checked={isSelected}
        //defaultChecked={isSelected}
        style={{ width: "16px", marginRight: "10px", paddingLeft: "30px" }}
      />
      {children}
    </components.Option>
  );
};

export const SelectListMulti = ({
  label,
  inputId,
  inputName,
  labelVisibility = true,
  placeholder,
  errors,
  touched,
  values,
  onBlur,
  onChange,
  dropdownItems = [],
  icon,
  hintText,
  disableArrow,
  uppercase,
  placeH,
  iconArrow = true,
  isMulti = false,
  errorHeight0,
  defaultVal = false,
  clearMulti = "",
  setClearSelect,
  isDisabled = false,
  isValueReadable = false,
  hideSelected = false,
  onChange2,
  isDynamic = false,
  placeHolderValue = true,
  isClearable = false,
}) => {
  let inputProps;
  let dropdownVal;
  if (!isMulti) {
    if (values) {
      if (dropdownItems.length > 0) {
        dropdownVal = dropdownItems.find(
          (dropdownItems) => dropdownItems.value === values
        );
      }
      inputProps = {
        value: dropdownVal,
      };
    }
  } else {
    if (values) {
      if (!isValueReadable) {
        if (dropdownItems.length > 0) {
          dropdownVal = dropdownItems.find(
            (dropdownItems) => dropdownItems.value === values
          );
        }

        inputProps = {
          //value: values[inputName],
          value: dropdownVal,
        };
      } else {
        inputProps = {
          //value: values[inputName],
          value: values,
          isOptionDisabled: () => values.length == 8,
        };
      }
    }
  }

  let dafaultValues = [];
  if (isMulti) {
    // console.log("values", values);
    if (values?.length > 0) {
      values?.forEach((el, ind) => {
        dropdownItems.find((element, index) => {
          // console.log("dropdownItems===", element.value, el);
          if (element.value === el) {
            dafaultValues.push(element);
          }
          return element.value === el;
        });
      });
    }
  }
  useEffect(() => {
    if (setClearSelect) {
      // console.log("setClearSelectsetClearSelectsetClearSelect");
      setClearSelect(!clearMulti);
    }
  }, [values]);

  let [isClicked, setIsClicked] = useState(false);

  const errorSelectClassname =
    errors[inputName] && isClicked
      ? `${styles.form_control} ${styles.form_control_error}`
      : `${styles.form_control}`;

  const selectClassName = disableArrow ? "arrowDisable" : "";

  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };

  const handleClick = () => {
    setIsClicked(true);
    //console.log(errors[inputName], isClicked);
  };

  let labelUppercase = uppercase ? `${styles.uppercase}` : "";

  let formControlClassname = icon
    ? `${styles.form_control_select} ${styles.select_with_icon}`
    : `${styles.form_control_select} `;

  formControlClassname += isMulti
    ? ` ${styles.form_control_select_multi} `
    : "";
  formControlClassname += isValueReadable
    ? `${styles.form_control_select_readable}`
    : "";

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#efefef" : "#efefef",
      boxShadow: "none",
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#efefef" : "#efefef",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: placeHolderValue === false ? "black" : "hsl(0, 0%, 50%)",
      };
    },
    option: (provided, state) => ({
      ...provided,

      background: state.isSelected ? "#EFEFEF" : "#FFFFFF",
      color: "black",
      minHeight: "45px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        background: "#EFEFEF",
      },
    }),
  };

  const renderSelect = () => {
    if (!isMulti) {
      if (isDynamic) {
        return (
          <Select
            components={{
              DropdownIndicator: () => {
                return iconArrow ? (
                  <img src={arrow} style={{ marginRight: "5px" }} />
                ) : null;
              },
              /* IndicatorSeparator: () => <img src={icon} />, */
            }}
            styles={customStyles}
            placeholder={placeH}
            options={dropdownItems}
            id={inputId}
            name={inputName}
            hideSelectedOptions={hideSelected}
            isDisabled={isDisabled}
            value={defaultValue(dropdownItems, values)}
            defaultValue={defaultVal}
            onBlur={onBlur}
            controlShouldRenderValue={placeHolderValue}
            key={`my_unique_sele${clearMulti}`}
            {...inputProps}
            onChange={(val) => {
              // console.log("select value =", val);
              onChange(inputName, val.value);
              onChange2(inputName, val.value);
            }}
            className={`${selectClassName}`}
            onMenuClose={() => handleClick()}
          />
        );
      } else {
        return (
          <Select
            components={{
              DropdownIndicator: () => {
                return iconArrow ? (
                  <img src={arrow} style={{ marginRight: "5px" }} />
                ) : null;
              },
              /* IndicatorSeparator: () => <img src={icon} />, */
            }}
            styles={customStyles}
            placeholder={placeH}
            options={dropdownItems}
            id={inputId}
            name={inputName}
            hideSelectedOptions={hideSelected}
            isDisabled={isDisabled}
            value={defaultValue(dropdownItems, values)}
            isClearable={isClearable}
            defaultValue={defaultVal}
            onBlur={onBlur}
            key={`my_unique_sele${clearMulti}`}
            {...inputProps}
            onChange={(val) => {
              // console.log("select value =", val);
              if (!!val) {
                onChange(inputName, val.value);
              } else {
                onChange(inputName, "");
              }
              if (onChange2) {
                onChange2(inputName, val.value);
              }
            }}
            className={`${selectClassName}`}
            onMenuClose={() => handleClick()}
          />
        );
      }
    } else {
      return (
        <Select
          components={{
            Option: InputOption,
            DropdownIndicator: () => {
              return iconArrow ? (
                <img src={arrow} style={{ marginRight: "5px" }} />
              ) : null;
            },
            /* IndicatorSeparator: () => <img src={icon} />, */
          }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          styles={customStyles}
          placeholder={placeH}
          options={dropdownItems}
          id={inputId}
          name={inputName}
          defaultValue={dafaultValues}
          onBlur={onBlur}
          key={`my_unique_sele${clearMulti}`}
          isMulti={isMulti}
          onChange={(options) => {
            if (Array.isArray(options)) {
              let selectOp = options.map((opt) => opt.value);
              onChange(inputName, selectOp);
            }
          }}
          className={`${selectClassName}`}
          onMenuClose={() => handleClick()}
        />
      );
    }
  };

  return (
    <div className={errorSelectClassname}>
      {labelVisibility && (
        <label htmlFor={inputId} className={labelUppercase}>
          {label}
        </label>
      )}
      <div className={formControlClassname}>
        {renderSelect()}
        {icon && (
          <Box pl="3" className="iconic_left_input">
            <img src={icon} alt="arrow icon" />
          </Box>
        )}

        {errors[inputName] && isClicked && (
          <div
            className={`${styles.error} ${styles.error} ${
              errorHeight0 ? styles.h_0 : ""
            }`}
          >
            {errors[inputName]}
          </div>
        )}
        {hintText && <Text className={`hint_sm`}>{hintText}</Text>}
      </div>
    </div>
  );
};
