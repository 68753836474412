import React, { useRef } from "react";
import {
  Box,
  Text,
  Button,
  Image,
  Center,
  Spinner,
  Card,
  Heading,
  CardBody,
  Stack,
  CardFooter,
  Grid,
  GridItem,
  StackDivider,
  CardHeader,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  Accordion,
  AccordionIcon,
  InputRightElement,
  InputLeftElement,
  Input,
  InputGroup,
  TabPanel,
  TabPanels,
  TabList,
  Tabs,
  Tab,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import { MainButton } from "../button/MainButton";
import { useTranslation } from "react-i18next";

import styles from "./couponCard.module.scss";

export const CouponCard = ({
  title,
  icon,
  path,
  secondUrl,
  onCopiedOpen,
  code,
  storeUrl,
  setStoreUrlCopied,
  storeName,
  desc,
  couponDesc,
  setCouponID,
  id,
  setStoreID,
  storeID,
  setCouponCode,
}) => {
  const linkAnchor = useRef(null);
  const { t } = useTranslation();

  const handleClickUrl = () => {
    setCouponID(id);

    setStoreID(storeID);
    setTimeout(() => {
      // linkAnchor.current.click();
      let url = storeUrl;
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url;
      }
      // window.open(`${url}`, "_blank");
      let n = window.open(`${url}`, "_blank");
      if (n == null) {
        alert(t("popupBlocked"));
        return true;
      }
      return false;
    }, 3000);
  };
  return (
    <>
      <Card
        background="white"
        borderRadius="25px"
        padding={5}
        marginBottom={6}
        // key={id}
      >
        <CardHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Image src={`${icon}`} maxWidth="150px" maxHeight="150px" />
            <Heading size="lg" paddingX={8} fontWeight="400">
              {`${title}`}
            </Heading>
          </Box>
          <Text color="#6AEFAB" fontSize="22px">
            {`${storeName}`}
          </Text>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box display="flex" alignItems="center">
              <Heading
                size="md"
                fontWeight="400"
                textTransform="uppercase"
                paddingRight={4}
                width="60%"
              >
                {`${couponDesc}`.length > 40
                  ? `${couponDesc}`.substring(0, 40) + "..."
                  : couponDesc}
              </Heading>
              <Box width="40%">
                <MainButton
                  title={t("Activate Deal")}
                  btnWidthFull={true}
                  onPress={() => {
                    window.dataLayer.push({
                      event: "open-coupon-click",
                    });
                    onCopiedOpen();

                    setCouponCode(`${code}`);
                    setStoreUrlCopied(`${storeUrl}`);
                    navigator.clipboard.writeText(`${code}`);
                    handleClickUrl();
                  }}
                  /* onPress={() => {
                                      onCopiedOpen();
                                      navigator.clipboard.writeText(
                                        "Copy this text to clipboard"
                                      );
                                    }} */
                  className="coupon_card-copy-btn"
                />
              </Box>
            </Box>

            <Accordion allowMultiple>
              <AccordionItem className="accordion_brand">
                <h2>
                  <AccordionButton
                    onClick={() =>
                      window.dataLayer.push({
                        event: "coupon-accordion-details-button",
                      })
                    }
                  >
                    <Box>
                      <Heading
                        size="xs"
                        textTransform="uppercase"
                        color="#022E5B"
                        className="heading_arrow"
                      >
                        {t("Show Details")}
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{desc}</AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
};
