export default {
  api: {
    baseURL: process.env.REACT_APP_API_BASE,
    loginBaseURL: process.env.REACT_APP_API_LOGIN,
  },
  apiLanding: {
    baseURL: process.env.REACT_APP_LANDING_API_BASE,
    loginBaseURL: process.env.REACT_APP_LANDING_API_LOGIN,
  },
};
