import React, { useState, useEffect, useCallback } from "react";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";

import {
  GetStoresAPI,
  DeleteStoreByIdAPI,
  DeactiveActiveStoreByIdAPI,
} from "../../api/StoreAPI";

import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import { StoresTable } from "./StoresTable";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";

import doc from "../../assets/images/iconsSvg/doc.svg";
import plus from "../../assets/images/iconsSvg/plus.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const StoresHistory = () => {
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [StoresData, setStoresData] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [activeOrIn, setActiveOrIn] = useState("0");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetStoresAPI()
      .then((res) => {
        setStoresData(res);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const renderRowsAfterDeletion = (id) => {
    if (!id.startsWith("deOrActive-")) {
      return;
    }
    // console.log('id.split("-")[1], activeOrIn', id.split("-")[1], activeOrIn);
    DeactiveActiveStoreByIdAPI(id.split("-")[1], activeOrIn)
      .then((res) => {
        setErrorMessage("");
        setIsLoading(true);
        loadTableData();

        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="Stores" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {StoresData?.data?.length <= 0 ? (
            <>
              <MainTitle title="Stores" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">No Stores created yet</Text>
                    </Box>
                    <MainButton
                      title="Create New"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/Admin/Store"
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <StoresTable
                StoresData={StoresData}
                setSelectedID={setSelectedID}
                onDelModalOpen={onDelModalOpen}
                setActiveOrIn={setActiveOrIn}
              />
            </>
          )}
        </>
      )}

      <ModalAlert
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        title="Are you sure?"
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={true}
        haveSaveDraft={true}
        isDelete={true}
        selectedID={selectedID}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        renderRowsAfterDeletion={renderRowsAfterDeletion}
      />

      <ModalAlert
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={errorMessage}
        icon={infoCircle}
        hasDesc={false}
        confirmationModal={false}
      />
    </>
  );
};
