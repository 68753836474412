import React, { useRef, useState, useEffect } from "react";

import { Route, Routes } from "react-router-dom";

import styles from "./main.module.scss";

import { Header } from "../header";
import { Home } from "../../../pages/landing";
import { ErrorPage } from "../../../pages/landing/ErrorPage";
import { Search } from "../../../pages/landing/Search";
import { AllCategories } from "../../../pages/landing/AllCategories";
import { AllStores } from "../../../pages/landing/AllStores";
import { BrandDetails } from "../../../pages/landing/BrandDetails";
import { Store } from "../../../pages/landing/Store";
import { AboutUs } from "../../../pages/landing/AboutUs";
import { Footer } from "../footer";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export const Main = ({ isOpen }) => {
  const baseRouteUrl = "/:locale(pt|en)?";
  const baseUrl = "/" + i18n.language;

  const url = window.location.pathname.split("/").pop();
  const analytics = (w, d, s, l, i) => {
    w.dataLayer = window.dataLayer || [];
    w.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var dl = l != "dataLayer" ? "&l=" + l : "";
    var scr = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    /*
        To avoid Multiple installations of google tag manager detected warning
    */
    if (!scriptExists(scr)) {
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement("script");
      j.async = true;
      j.src = scr;
      f?.parentNode?.insertBefore(j, f);
    }
  };
  const scriptExists = (url) => {
    var scripts = document.getElementsByTagName("script");
    for (var i = scripts.length; i--; ) {
      if (scripts[i].src == url) return true;
    }
    return false;
  };
  useEffect(() => {
    analytics(window, document, "script", "dataLayer", "GTM-PGD3299");

    console.log(`You changed the page to: }`);
  }, [url]);
  // useEffect(() => {
  //   analytics(window, document, "script", "dataLayer", "GTM-PGD3299");
  //   console.log("move");
  // }, []);
  const navbarRef = useRef(null);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 100) {
      navbarRef.current.classList.add(`${styles.navbar_scroll}`);
    } else {
      navbarRef.current.classList.remove(`${styles.navbar_scroll}`);
    }
  };

  const renderRoutes = () => {
    return (
      <Routes>
        <Route path={`${baseUrl}/`} element={<Home />} />
        <Route path={`${baseUrl}/AllCategories`} element={<AllCategories />} />
        <Route path={`${baseUrl}/AllStores`} element={<AllStores />} />
        <Route path={`${baseUrl}/Category/:id`} element={<BrandDetails />} />
        <Route path={`${baseUrl}/Store/:id`} element={<Store />} />
        <Route path={`${baseUrl}/About`} element={<AboutUs />} />
        <Route path={`${baseUrl}/Search`} element={<Search />} />
        <Route path={`${baseUrl}/404`} element={<ErrorPage />} />
        <Route path={`${baseUrl}/*`} element={<ErrorPage />} />
      </Routes>
    );
  };

  return (
    <div id="main_content" className={styles.content} onScroll={handleScroll}>
      <Header ref={navbarRef} isOpen={isOpen} />
      <div className={styles.container}>{renderRoutes()}</div>
      <Footer />
    </div>
  );
};
