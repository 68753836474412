import react, { useState, useEffect, useCallback, useRef } from "react";

import { Box, HStack, Text, Center, Spinner } from "@chakra-ui/react";

import { activeMenuItem } from "../utils/General";
import { MainTitle } from "../components/mainTitle/MainTitle";
import { MainBox } from "../components/mainBox/MainBox";

export const DashboardHome = () => {
  useEffect(() => {
    document.getElementById("main_content").scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MainTitle title="Home" />

      <MainBox pb={0}>
        <Box className="table_content" pt={6} pb={0}>
          <MainTitle title={"Welcome to Glu Coupon"} />
          <Text paddingBottom={6} fontSize="20px" letterSpacing="1px">
            Lorem Lorem lorem lorem lorem lorem Lorem Lorem lorem lorem lorem
            lorem Lorem Lorem lorem lorem lorem lorem Lorem Lorem lorem lorem
            lorem lorem
          </Text>
        </Box>
      </MainBox>
    </div>
  );
};
