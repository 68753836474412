import axios from "axios";
import config from "../../config";
import Cookies from "js-cookie";

const AxiosClient = axios.create({
  baseURL: config.apiLanding.baseURL,
});
export const AxiosClientGet = axios.create({
  baseURL: config.apiLanding.baseURL,
  // headers: {
  //   //"Content-Type": "multipart/form-data",
  //   // "x-access-token": Cookies.get("token"),
  //   // Authorization: `Basic ${Cookies.get("token")}`,
  //   Authorization: `Basic eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGdsb2NvdXBvbi5jb20iLCJ1c2VySWQiOiIxIiwiaWF0IjoxNjg0NTEzNjA4LCJleHAiOjE2ODUxMTg0MDh9.B1rWLuOiOaw-RGtQTXkFwS8WkY70MYnKukmRCbXNQwY`,
  //   "Content-Type": "multipart/form-data",
  // },
});

export const AxiosClientPost = axios.create({
  baseURL: config.apiLanding.baseURL,
  //withCredentials: true,
  headers: {
    //"Content-Type": "multipart/form-data",
    // "x-access-token": Cookies.get("token"),
    Authorization: `Basic ${Cookies.get("token")}`,
  },
});

export const AxiosClientMultipart = axios.create({
  baseURL: config.apiLanding.baseURL,
  //"Content-Type": "application/x-www-form-urlencoded",
  //"Access-Control-Allow-Credentials": true,
  //withCredentials: true,
  headers: {
    Authorization: `Basic ${Cookies.get("token")}`,
    "Content-Type": "multipart/form-data",
  },
});

export default AxiosClient;
