import React, { useState } from "react";
import { GetSubCategoryOnCategoryByIdAPI } from "../../api/SubCategoryAPI";

import {
  Box,
  Divider,
  Flex,
  SimpleGrid,
  GridItem,
  Text,
  Image,
  Input,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { TextArea } from "../../components/textArea/TextArea";
import { SelectListMulti } from "../../components/selectField/SelectListMulti";
import Cookies from "js-cookie";

export const StoreForm = ({
  errors,
  touched,
  setFieldValue,
  handleBlur,
  formikValues,
  dirty,
  isValid,
  handleOnSubmit,
  generalData,
  dropdownObj,
  setDropdownObj,
}) => {
  const [isClearable, setIsClearable] = useState(false);

  const handleChange = (e, e2) => {
    GetSubCategoryOnCategoryByIdAPI(e2)
      .then((res) => {
        if (res.subs.length >= 0) {
          let arr = [];
          res.subs.forEach((item) => {
            arr.push({
              value: item.id,
              label: item.name_en,
            });
          });

          setFieldValue("sub_category_id", "");
          setIsClearable(!isClearable);
          setDropdownObj({ ...dropdownObj, subCat: arr });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeRadio = (event, name) => {
    setFieldValue(name, event);
  };
  return (
    <>
      <Box
        maxW={["100%", "100%", "70%"]}
        width={["100%", "100%", "70%"]}
        alignSelf="flex-start"
      >
        <Text
          className="title_sm"
          display="flex"
          mb={2}
          mt={0}
          component="div"
          textTransform="uppercase"
          fontSize="12px"
          letterSpacing="0.04em"
        >
          Image
        </Text>
        {!!generalData?.image && (
          <Image
            width="150px"
            objectFit="cover"
            height="150px"
            src={`https://www.glocoupon.com/api2/${generalData.image}`}
          />
        )}
        <Input
          paddingTop="5px"
          marginBottom="15px"
          size="md"
          type="file"
          name="image"
          errors={errors}
          touched={touched}
          values={formikValues.image}
          accept="image/*"
          onChange={(event) => {
            setFieldValue("image", event.currentTarget.files[0]);
          }}
        />
        <InputFieldValidation
          label="name_en"
          inputType="text"
          inputId="name_en"
          inputName="name_en"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
        <InputFieldValidation
          label="name_ar"
          inputType="text"
          inputId="name_ar"
          inputName="name_ar"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />

        <TextArea
          label="description_en"
          inputId="description_en"
          inputName="description_en"
          placeholder="Add a description en"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="description_ar"
          inputId="description_ar"
          inputName="description_ar"
          placeholder="Add a description_ar"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="seo_en"
          inputId="seo_en"
          inputName="seo_en"
          placeholder="Add a seo en"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <TextArea
          label="seo_ar"
          inputId="seo_ar"
          inputName="seo_ar"
          placeholder="Add a seo ar"
          errors={errors}
          touched={touched}
          isRequired
          values={formikValues}
          onChange={setFieldValue}
          onBlur={handleBlur}
        />
        <InputFieldValidation
          label="url"
          inputType="text"
          inputId="url"
          inputName="url"
          errors={errors}
          touched={touched}
          isRequired
          onChange={setFieldValue}
          values={formikValues}
        />
      </Box>
      <Box
        maxW={["100%", "100%", "100%"]}
        width={["100%", "100%", "100%"]}
        alignSelf="flex-start"
      >
        <SimpleGrid columns={{ sm: 8, md: 8 }} spacingX="15px" width="100%">
          <GridItem colSpan={8}>
            <SimpleGrid columns={{ sm: 8, md: 8 }} spacing="15px" width="100%">
              <GridItem colSpan={2}>
                <SelectListMulti
                  dropdownItems={dropdownObj.type}
                  label="store_type_id"
                  inputId="store_type_id"
                  inputName="store_type_id"
                  placeholder="Select"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={formikValues?.store_type_id}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <SelectListMulti
                  dropdownItems={dropdownObj.category}
                  label="category_id"
                  inputId="category_id"
                  inputName="category_id"
                  placeholder="Select"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={formikValues?.category_id}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                  isDynamic={true}
                  onChange2={(e, e2) => handleChange(e, e2)}
                />
              </GridItem>
              {/* <GridItem colSpan={2}>
                <SelectListMulti
                  dropdownItems={dropdownObj.subCat}
                  label="sub_category_id"
                  inputId="sub_category_id"
                  inputName="sub_category_id"
                  placeholder="Select"
                  errors={errors}
                  touched={touched}
                  isRequired
                  values={formikValues?.sub_category_id}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  uppercase={true}
                  clearMulti={isClearable}
                />
              </GridItem> */}
            </SimpleGrid>
          </GridItem>
        </SimpleGrid>
      </Box>

      <Divider orientation="horizontal" mt="20px" mb="30px" />
      <Flex justifyContent="flex-end" alignItems="center" width="100%">
        <Box display="flex" gap="30px">
          <MainButton
            title="Cancel"
            btnStyle="transparent"
            fontColor="light"
            navLink={true}
            path={`/Admin/StoresHistory`}
          />

          <MainButton
            title="Submit"
            reverseRow={true}
            disabledBtn={isValid && dirty ? false : true}
            onPress={() => {
              handleOnSubmit(formikValues);
            }}
          />
        </Box>
      </Flex>
    </>
  );
};
