export const activeMenuItem = () => {
  let hometItem = document
    .querySelector(".sidebar_dropdown")
    ?.parentElement?.querySelector("#Home");
  let aboutItem = document
    .querySelector(".sidebar_dropdown")
    ?.parentElement?.querySelector("#AboutUs");
  let mediaItem = document
    .querySelector(".sidebar_dropdown")
    ?.parentElement?.querySelector("#MediaCenter");
  let contactItem = document
    .querySelector(".sidebar_dropdown")
    ?.parentElement?.querySelector("#Contacts");
  let masterItem = document
    .querySelector(".sidebar_dropdown")
    ?.parentElement?.parentElement?.querySelector(".masterData");
  // console.log("window.location.pathname", window.location.pathname);
  if (
    (window.location.pathname.includes("Journey") &&
      !window.location.pathname.includes("JourneysHistory")) ||
    (window.location.pathname.includes("Factory") &&
      !window.location.pathname.includes("FactoryHistory")) ||
    (window.location.pathname.includes("Services") &&
      !window.location.pathname.includes("ServicesHistory"))
  ) {
    aboutItem?.classList?.add("activeH3");
  } else if (
    (window.location.pathname.includes("Banner") &&
      !window.location.pathname.includes("BannersHistory")) ||
    (window.location.pathname.includes("UniqueRock") &&
      !window.location.pathname.includes("uniqueRockHistory"))
  ) {
    hometItem?.classList?.add("activeH3");
  } else if (
    (window.location.pathname.includes("Media") &&
      !window.location.pathname.includes("MediaHistory")) ||
    (window.location.pathname.includes("PhotoGallery") &&
      !window.location.pathname.includes("PhotoGalleryHistory"))
  ) {
    //console.log("enter hotel");
    mediaItem?.classList?.add("activeH3");
  } else if (
    (window.location.pathname.includes("ContactForm") &&
      !window.location.pathname.includes("ContactFormHistory")) ||
    (window.location.pathname.includes("Careers") &&
      !window.location.pathname.includes("CareersHistory"))
  ) {
    //console.log("enter hotel");
    contactItem?.classList?.add("activeH3");
  } /* else if (
    window.location.pathname.includes("MasterData") &&
    !window.location.pathname.includes("MasterDataHome")
  ) {
    aboutItem?.classList?.remove("activeH3");
    mediaItem?.classList?.remove("activeH3");
    masterItem?.classList?.add("activeH3");
    //console.log("eeeelse");
  }  */ else {
    //console.log("enter else");
    // masterItem?.classList?.remove("activeH3");
    aboutItem?.classList?.remove("activeH3");
    contactItem?.classList?.remove("activeH3");
    hometItem?.classList?.remove("activeH3");
    mediaItem?.classList?.remove("activeH3");
  }
};

export const convertEmpStringsArrToZero = (array) => {
  let newArr = [];
  newArr = array.map((num) => {
    if (num === "") {
      return 0;
    } else {
      return num;
    }
  });
  return newArr;
};

export const nullifyYearsField = (
  step,
  startDate,
  formikValues,
  setFieldValue,
  formikStartDate,
  fieldName,
  otherRevenueBreakdown = false
) => {
  if (step.warning) {
    if (!isNaN(startDate)) {
      var difference =
        new Date(formikValues[formikStartDate]).getFullYear() - startDate;

      if (difference !== 0) {
        if (otherRevenueBreakdown) {
          fieldName.forEach((element) => {
            // console.log("element", element);
            setFieldValue(
              `otherRevenueBreakdown.${element}`,
              formikValues?.otherRevenueBreakdown[element].fill("")
            );
          });
        } else {
          fieldName.forEach((element) => {
            // console.log("element", element);
            setFieldValue(element, formikValues[element].fill(""));
          });
        }
      }
    }
  }
};

export const selectedRadioButton = (val) => {
  if (val === "2") {
    return false;
  } else if (val === "1") {
    return true;
  } else {
    return null;
  }
};

export const isDecimalNumber = (n) => {
  var result = n - Math.floor(n) !== 0;

  if (result) return true;
  else return false;
};
