import { Box, Text, Button, Image, GridItem, Grid } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { MainButton } from "../button/MainButton";
import { useTranslation } from "react-i18next";

import styles from "./categoriesCard.module.scss";

export const CategoriesCard = ({
  title,
  icon,
  path,
  secondUrl,
  // categories,
}) => {
  const { i18n, t } = useTranslation();

  const renderCategories = (categories) => {
    return categories.map((category, ind) => {
      return (
        <GridItem>
          <NavLink
            className={`${styles.center} ${styles.catLink}`}
            to={category.path}
            key={`${category.titleEn}-${ind}`}
          >
            {i18n.language == "en" ? category.titleEn : category.titleAr}
          </NavLink>
        </GridItem>
      );
    });
  };
  return (
    <>
      <Box className={` ${styles.category}`} display="flex">
        {icon &&
          (i18n.language == "en" ? (
            <Box mr={16}>
              <Image paddingY={3} src={icon} alt="icon" />
            </Box>
          ) : (
            <Box ml={16}>
              <Image paddingY={3} src={icon} alt="icon" />
            </Box>
          ))}

        <Box className={` ${styles.wrapper}`}>
          <Box>
            <Image
              className={`${styles.img1}`}
              paddingY={3}
              src={icon}
              alt="icon"
            />
          </Box>
          <NavLink
            to={path}
            onClick={() =>
              window.dataLayer.push({
                event: "category-card-click",
              })
            }
          >
            <Text
              className={`${styles.title}`}
              fontSize="34px"
              lineHeight="20px"
              marginY={3}
              color="#022E5B"
            >
              {title}
            </Text>
          </NavLink>

          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={6}
            width="100%"
            marginY={10}
            className={`${styles.grid}`}
          >
            {/* {renderCategories(categories)} */}
          </Grid>
        </Box>
      </Box>
    </>
  );
};
