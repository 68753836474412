// import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost, AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//Sub
export const GetSubCategoriesAPI = async (page = 1) => {
  const URL = `/getAllSubCategories?page=${page}`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetSubCategoryByIdAPI = async (id) => {
  const URL = `/getSubCategory/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetSubCategoryOnCategoryByIdAPI = async (id) => {
  const URL = `/getAllSubsOnCategory/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddSubCategoryAPI = async (payload) => {
  const URL = "/addSubCategory";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateSubCategoryAPI = async (payload) => {
  // const URL = `/addCategory/${id}`;
  const URL = `/editSubCategory`;
  try {
    const response = await AxiosClientMultipart.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteStoreByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeactiveActiveSubCategoryeByIdAPI = async (id, active = 0) => {
  const URL = `/activateOrDeactivateSub/${id}?number=${active}`;
  try {
    const response = await AxiosClientPost.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const GetSubCategoriesAPI = async (page = 1) => {
//   const URL = `/getAllSubCategories?page=${page}`;
//   try {
//     const response = await AxiosClientGet.get(URL);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
