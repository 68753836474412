import { Heading, Box } from "@chakra-ui/react";

export const MainTitle = ({
  title,
  pb = 5,
  pt = 2,
  px = 0,
  transform = "uppercase",
  bBottom = false,
}) => {
  let transformClass = transform === "unset" ? "transUnset" : "uppercase";
  return (
    <Box>
      <Heading
        fontSize="27px"
        className={`main_title ${transformClass}`}
        pb={pb}
        pt={pt}
        px={px}
        textTransform="lowercase"
        borderBottom={bBottom ? "3px solid #efefef" : "0px solid #efefef"}
      >
        {title}
      </Heading>
    </Box>
  );
};
