import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost, AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//BANNERS
export const GetBannersAPI = async (limit = 10, offset = 0) => {
  // const URL = `/banners`;
  const URL = `/banners/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetBannerByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddBannerAPI = async (payload) => {
  const URL = "/banners";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateBannerAPI = async (id, payload) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteBannerByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//UNIQUE ROCK
export const GetUniqueRocksAPI = async (limit = 10, offset = 0, page = 1) => {
  offset = (page - 1) * limit;
  const URL = `/uniqueRock/lang?lang=ar&limit=${limit}&offset=${offset}`;
  try {
    const response = await axiosClient.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetUniqueRockByIdAPI = async (id) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await axiosClient.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddUniqueRockAPI = async (payload) => {
  const URL = "/uniqueRock";
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateUniqueRockAPI = async (id, payload) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteUniqueRockByIdAPI = async (id) => {
  const URL = `/uniqueRock/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//ABOUT US
export const GetAboutAPI = async () => {
  const URL = `/abouts`;
  try {
    const response = await AxiosClientGet.get(URL, {
      /*  params: {
        token: Cookies.get("token"),
      }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateAboutAPI = async (payload) => {
  const URL = `/abouts`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Types
export const GetTypesAPI = async () => {
  const URL = `/types`;
  try {
    const response = await AxiosClientGet.get(URL, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddTypesAPI = async (payload) => {
  const URL = `/types`;
  try {
    const response = await AxiosClientPost.post(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateTypesAPI = async (payload) => {
  const URL = `/types`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetTypesByIdAPI = async (id) => {
  const URL = `/types/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//UPLOAD
export const UploadAPI = async (payload) => {
  const URL = "/images";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      /* headers: {
        "Content-Type": "multipart/form-data",
      }, */
      ...payload,
      token: Cookies.get("token"),
      //"Content-Type": "multipart/form-data",
      /* headers: {
        "   -Type": "multipart/form-data",
      }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
