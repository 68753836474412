import react from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import "../../assets/styles/landing/err.scss";

export const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>Error Page</title>
        <meta name="description" content="Error Page" />
      </Helmet>

      <div class="outer-wrapper">
        <div className="displayOnDesk">
          <div class="inner">
            <div class="four-wrapper">
              <div class="leg-1"></div>
              <div class="leg-2"></div>
              <div class="leg-3"></div>
            </div>

            <div class="zero-wrapper">
              <div class="zero-wrapper__middle"></div>
            </div>

            <div class="four-wrapper">
              <div class="leg-1"></div>
              <div class="leg-2"></div>
              <div class="leg-3"></div>
            </div>
          </div>
        </div>
        <div className="displayOnRes">404</div>

        <h1>{t("Page not found")}</h1>
      </div>
    </div>
  );
};
