import react, { useState, useEffect, useCallback, useRef } from "react";

import {
  Box,
  Text,
  Center,
  Spinner,
  Image,
  InputRightElement,
  InputLeftElement,
  Input,
  InputGroup,
  Button,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  useDisclosure,
  HStack,
  Card,
  Heading,
  CardBody,
  Stack,
  Grid,
  GridItem,
  TabPanel,
  TabPanels,
  TabList,
  Tabs,
  Tab,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { MainTitle } from "../../components/landing/mainTitle/MainTitle";
import { MainBox } from "../../components/landing/mainBox/MainBox";
import { BrandCard } from "../../components/landing/brandCard/BrandCard";
import { ModalAlert2 } from "../../components/landing//modalAlert/ModalAlert2";

import logo from "../../assets/images/logo.png";

import { GetAboutAPI } from "../../api/landing/StoreAPI";
import checked from "../../assets/images/success.png";

export const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [generalData, setGeneralData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [generalData2, setGeneralData2] = useState([]);
  const [isLoading2, setIsLoading2] = useState(true);

  const [aboutDetails, setAboutDetails] = useState([]);
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetAboutAPI()
      .then((res) => {
        setAboutDetails(res.abouts[0]);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        onErrorOpen();
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <div>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="About Us" />
      </Helmet>

      <Box marginTop="15px">
        <MainTitle px={16} pt={8} pb={8} title={t("About Us")} />
      </Box>
      <Box>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink color="#706F6F" href="/">
              {t("Home")}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink color="#022E5B" href="/AllStores">
              {t("About Us")}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>

      <Box display="flex" flexWrap="wrap" marginTop="20px" gap="2%">
        {isLoading ? (
          <>
            <MainBox>
              <Box paddingTop={12} paddingBottom={12}>
                <Center>
                  <Spinner />
                </Center>
              </Box>
            </MainBox>
          </>
        ) : (
          <>
            <Card
              direction={{ base: "column", sm: "row" }}
              overflow="hidden"
              variant="outline"
              minHeight="340px"
              background="white"
              marginBottom="30px"
              className="about_card coupon_card"
              padding="40px 60px"
              borderRadius="25px"
              width="100%"
            >
              <Stack flex="1">
                <CardBody className="coupon_card-body" alignItems="center">
                  {/*  <Heading size="md" color="#022E5B" fontSize="30px">
                    {i18n.language == "en"
                      ? generalData.name_en
                      : generalData.name_ar}
                  </Heading> */}

                  <Text py="2" color="#022E5B" fontSize="22px">
                    {i18n.language == "en"
                      ? aboutDetails.description_en
                      : aboutDetails.description_ar}
                  </Text>
                </CardBody>
              </Stack>
            </Card>
          </>
        )}
      </Box>

      {/* <Box
        background="white"
        position="absolute"
        left="0"
        right="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        paddingX="55px"
        paddingBottom={16}
        className="section_bef-footer "
      >
        <Image src={logo} alt="logo" width="200px" paddingY={12} />

        <Box
          marginTop={6}
          borderRadius="25px"
          background="#022F59"
          display="flex"
          padding="20px"
          width="100%"
        >
          <Box width="50%">
            <Text color="white" fontSize="25px">
              {t("contact1")}
            </Text>
            <Text color="#6AEFAB" fontSize="25px">
              {t("contact2")}
            </Text>
          </Box>
          <Box width="50%">
            <InputGroup
              size="md"
              height="100%"
              background="white"
              borderRadius="10px"
            >
              <Input
                pr="4.5rem"
                // paddingLeft="65px"
                // variant="filled"
                placeholder=""
                height="100%"
                borderTopRightRadius="10px"
                borderBottomRightRadius="10px"
              />
              <InputRightElement
                width="4.5rem"
                background="#6AEFAB"
                borderTopRightRadius="10px"
                borderBottomRightRadius="10px"
                height="100%"
                paddingX={16}
                position="relative"
              >
                <Button
                  // h="1.75rem"
                  size="sm"
                  background="#6AEFAB" onClick={handleClick}
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  height="100%"
                >
                  <Text>{t("Subscribe")} </Text>
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </Box>
      </Box>
      <Box className="section_bef-footer2" marginTop="350px"></Box> */}
      <ModalAlert2
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={t("Error")}
        desc={t("Please try again.")}
        btnWidthFull={true}
        icon={checked}
        hasDesc={true}
        isError={true}
      />
    </div>
  );
};
