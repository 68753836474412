import { useState, useEffect } from "react";
import { Box, Flex, HStack, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { getUsedCoupons } from "../../api/CouponAPI";

import { MainButton } from "../../components/button/MainButton";
import { TablePagination2 } from "../../components/tablePagination/TablePagination2";

export const UsedCouponTable = ({
  onDelModalOpen,
  UsedCouponData,
  setSelectedID,
  setActiveOrIn,
}) => {
  let [currPagData, setCurrPagData] = useState({
    page: 1,
  });

  const [dataArray, setDataArray] = useState([]);

  const columns = [
    {
      name: "Used Coupon ID",
      selector: (row) => row.idCustom,
    },
    {
      name: "COUPON ID",
      selector: (row) => row.coupon_id,
    },
    {
      name: "COUPON NAME EN",
      selector: (row) => row.name_en,
    },
    {
      name: "COUPON NAME AR",
      selector: (row) => row.name_ar,
    },
    {
      name: "STATUS NAME EN",
      selector: (row) =>
        row.status_name_en == "undefined" ? "" : row.status_name_en,
    },
    {
      name: "STATUS NAME AR",
      selector: (row) =>
        row.status_name_ar == "undefined" ? "" : row.status_name_ar,
    },
  ];

  useEffect(() => {
    let data = [];
    if (UsedCouponData?.userCouponsMap?.length > 0 && !!UsedCouponData) {
      UsedCouponData.userCouponsMap.forEach((item) => {
        let dataObj = {
          id: `${item.used_coupon_id + Math.floor(Math.random() * Date.now())}`,
          idCustom: `${item.used_coupon_id}`,
          coupon_id: `${item.coupon_id}`,
          name_en: `${item.coupon_name_en}`,
          name_ar: `${item.coupon_name_ar}`,
          code: `${item.coupon_code}`,
          status_name_en: `${item.status_name_en}`,
          status_name_ar: `${item.status_name_ar}`,
        };

        data.push(dataObj);
      });
      setDataArray(data);
    }
    setCurrPagData({
      page: 1,
    });
  }, []);

  const handleOnPageChange = (page) => {
    getUsedCoupons(page)
      .then((res) => {
        let data = [];

        if (res.userCouponsMap.length > 0) {
          res.userCouponsMap.forEach((item) => {
            let dataObj = {
              id: `${
                item.used_coupon_id + Math.floor(Math.random() * Date.now())
              }`,
              idCustom: `${item.used_coupon_id}`,
              coupon_id: `${item.coupon_id}`,
              name_en: `${item.coupon_name_en}`,
              name_ar: `${item.coupon_name_ar}`,
              code: `${item.coupon_code}`,
              status_name_en: `${item.status_name_en}`,
              status_name_ar: `${item.status_name_ar}`,
            };

            data.push(dataObj);
          });

          setDataArray(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HStack width="100%">
        <Tabs variant="unstyled" width="100%">
          <TabPanels>
            <TabPanel w="98%" px="0">
              <TablePagination2
                data={dataArray}
                columns={columns}
                isSearchable={false}
                title="Used Coupons"
                hasDelete={true}
                totalRows={UsedCouponData?.count}
                pageSize={10}
                // pageSize={BannersData?.limit}
                // pageSize={BannersData.pageSize}
                GetDataAPI={getUsedCoupons}
                handleOnPageChange={handleOnPageChange}
                setCurrPagData={setCurrPagData}
                currPagData={currPagData}
                haveCreateButton={false}
                path={`/Admin/UsedCoupons`}
                buttonTitle=""
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </>
  );
};
