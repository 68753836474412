import React, { useState, useEffect, useCallback } from "react";

import { Box, Text, Center, Spinner, useDisclosure } from "@chakra-ui/react";

import { getUsedCoupons } from "../../api/CouponAPI";

import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { MainButton } from "../../components/button/MainButton";
import { UsedCouponTable } from "./usedCouponTable";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";

import doc from "../../assets/images/iconsSvg/doc.svg";
import plus from "../../assets/images/iconsSvg/plus.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const UsedCouponHistory = () => {
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [UsedCouponsData, setUsedCouponData] = useState([]);
  const [selectedID, setSelectedID] = useState("-1");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeOrIn, setActiveOrIn] = useState("0");
  const [isLoading, setIsLoading] = useState(true);

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    getUsedCoupons()
      .then((res) => {
        setUsedCouponData(res);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <>
      {isLoading ? (
        <>
          <MainTitle title="Used Coupons" />
          <MainBox>
            <Box paddingTop={12} paddingBottom={12}>
              <Center>
                <Spinner />
              </Center>
            </Box>
          </MainBox>
        </>
      ) : (
        <>
          {UsedCouponsData?.data?.length <= 0 ? (
            <>
              <MainTitle title="Used Coupons" />
              <MainBox>
                <Box className="table_content" pt={6} pb={6}>
                  <Center flexDirection="column">
                    <img src={doc} alt="icon" />
                    <Box mt={3} mb={5}>
                      <Text className="title_sm">
                        No Used Coupons created yet
                      </Text>
                    </Box>
                    <MainButton
                      title="Create New"
                      marginRight="1"
                      icon={plus}
                      navLink={true}
                      path="/Admin/Category"
                    />
                  </Center>
                </Box>
              </MainBox>
            </>
          ) : (
            <>
              <UsedCouponTable
                UsedCouponData={UsedCouponsData}
                setSelectedID={setSelectedID}
                setActiveOrIn={setActiveOrIn}
                onDelModalOpen={onDelModalOpen}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
