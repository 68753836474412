import react, { useState, useEffect, useCallback, useRef } from "react";

import {
  GetStoresAPI,
  GetAllBanners,
  PostAllBanners,
} from "../../api/landing/StoreAPI";
import { GetCouponsAPI } from "../../api/landing/CouponAPI";
import {
  Box,
  HStack,
  Text,
  Center,
  Spinner,
  Image,
  InputRightElement,
  Input,
  InputGroup,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { MainTitle } from "../../components/landing//mainTitle/MainTitle";
import { MainBox } from "../../components/landing//mainBox/MainBox";
import { BrandCard } from "../../components/landing//brandCard/BrandCard";
import { ModalAlert2 } from "../../components/landing//modalAlert/ModalAlert2";
import { ModalAlert } from "../../components/landing//modalAlert/ModalAlert";

import logo from "../../assets/images/logo.png";
import { BrandDetailsCard } from "../../components/landing//brandDetailsCard/BrandDetailsCard";
import checked from "../../assets/images/success.png";

export const Home = () => {
  const {
    isOpen: isCopiedOpen,
    onOpen: onCopiedOpen,
    onClose: onCopiedClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const { t, i18n } = useTranslation();
  const baseUrl = "/" + i18n.language;

  const [errorMessage, setErrorMessage] = useState(`${t("Please try again.")}`);
  const [isLoading, setIsLoading] = useState(true);
  const [storeUrlCopied, setStoreUrlCopied] = useState("");
  const [couponID, setCouponID] = useState("");
  const [storeID, setStoreID] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const [CouponsData, setCouponsData] = useState([]);
  const [StoresData, setStoresData] = useState([]);
  const [bannerData, setBannerData] = useState([]);

  const loadTableData = useCallback(() => {
    document.getElementById("main_content").scrollTo(0, 0);

    GetStoresAPI()
      .then((res) => {
        setStoresData(res.storesMap);
        setIsLoading(false);
        //return res;
        // throw "err";
      })
      .catch((err) => {
        // window.location.replace("/404");
        onErrorOpen();
        setErrorMessage(err?.response?.data?.message || t("Please try again."));
        // console.log(err);
      });
    GetAllBanners()
      .then((res) => {
        setBannerData(res.bannersMap);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        // window.location.replace("/404");
        // console.log(err);
        onErrorOpen();
        setErrorMessage(err?.response?.data?.message || t("Please try again."));
      });
    GetCouponsAPI()
      .then((res) => {
        setCouponsData(res.couponsMap);
        setIsLoading(false);
        //return res;
      })
      .catch((err) => {
        // window.location.replace("/404");
        // console.log(err);
        onErrorOpen();
        setErrorMessage(err?.response?.data?.message || t("Please try again."));
      });
  }, []);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const handleClickUrl = (storeUrl) => {
    setTimeout(() => {
      let url = storeUrl;
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url;
      }
      // window.open(`${url}`, "_blank");
      let n = window.open(`${url}`, "_blank");
      if (n == null) {
        alert(t("popupBlocked"));
        return true;
      }
      return false;
    }, 3000);
  };
  return (
    <div>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Home Page" />
      </Helmet>
      <Box>
        <Text
          color="#022E5B"
          fontSize="30px"
          fontWeight="lighter"
          marginTop="15px"
          marginBottom="40px"
          textAlign="center"
          className="landing-title"
        >
          {t("Glo Coupon online shopping offers")}
        </Text>
      </Box>
      <Box className="carousel-home">
        <Carousel>
          {bannerData.map((el, ind) => {
            return (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  // src={banner1}
                  src={`https://www.glocoupon.com/api2/${el.store_image}`}
                  alt="First slide"
                  onClick={() => {
                    window.dataLayer.push({
                      event: "banner-image",
                    });
                    onCopiedOpen();
                    setStoreUrlCopied(`${el.store_url}`);
                    setCouponCode(`${el.coupon_code}`);
                    navigator.clipboard.writeText(`${el.coupon_code}`);
                    handleClickUrl(`${el.store_url}`);
                    setCouponID(el.coupon_id);
                    setStoreID(el.store_id);
                  }}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Box>
      <Box>
        <MainTitle px={16} pt={8} pb={8} title={t("Popular Stores")} />
      </Box>
      <Box display="flex" flexWrap="wrap" marginTop="20px" gap="2%">
        {isLoading ? (
          <>
            <MainBox>
              <Box paddingTop={12} paddingBottom={12}>
                <Center>
                  <Spinner />
                </Center>
              </Box>
            </MainBox>
          </>
        ) : (
          <>
            {StoresData.length > 0 ? (
              <>
                {StoresData?.map((el, ind) => {
                  if (ind > 4) return;
                  return (
                    <BrandCard
                      path={`${baseUrl}/store/${el.id}`}
                      title={`${
                        i18n.language == "en" ? el.name_en : el.name_ar
                      }`}
                      icon={`https://www.glocoupon.com/api2/${el.image}`}
                      key={el.id}
                    />
                  );
                })}
              </>
            ) : (
              <Box
                textAlign="center"
                paddingY={14}
                borderRadius="20px"
                background="white"
                fontSize="24px"
                width="100%"
              >
                {t("There is no coupons to show")}
              </Box>
            )}
          </>
        )}
      </Box>
      <Box>
        <MainTitle px={16} pt={8} pb={8} title={t("Today's Best Coupons")} />
      </Box>
      <Box display="flex" flexWrap="wrap" marginTop="20px" gap="2%">
        {isLoading ? (
          <>
            <MainBox>
              <Box paddingTop={12} paddingBottom={12}>
                <Center>
                  <Spinner />
                </Center>
              </Box>
            </MainBox>
          </>
        ) : (
          <>
            {CouponsData.length > 0 ? (
              <>
                {CouponsData?.map((el, ind) => {
                  return (
                    <BrandDetailsCard
                      path={`${baseUrl}/coupon/${el.id}`}
                      secondUrl={`store/${el.store_id}`}
                      onCopiedOpen={onCopiedOpen}
                      storeUrl={el.store_url}
                      code={el?.code}
                      setStoreUrlCopied={setStoreUrlCopied}
                      icon={`https://www.glocoupon.com/api2/${el.store_image}`}
                      title={`${
                        i18n.language == "en" ? el.name_en : el.name_ar
                      }`}
                      desc={`${
                        i18n.language == "en"
                          ? el.coupon_desc_en
                          : el.coupon_desc_ar
                      }`}
                      setCouponID={setCouponID}
                      id={el.id}
                      setCouponCode={setCouponCode}
                      setStoreID={setStoreID}
                      storeID={el.store_id}
                      storeName={`${
                        i18n.language == "en"
                          ? el.store_name_en
                          : el.store_name_ar
                      }`}
                    />
                  );
                })}
              </>
            ) : (
              <Box
                textAlign="center"
                paddingY={14}
                borderRadius="20px"
                background="white"
                fontSize="24px"
                width="100%"
              >
                {t("There is no coupons to show")}
              </Box>
            )}
          </>
        )}
      </Box>

      {/* <Box
        background="white"
        position="absolute"
        left="0"
        right="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        paddingX="55px"
        paddingBottom={16}
        className="section_bef-footer "
      >
        <Image src={logo} alt="logo" width="200px" paddingY={12} />

        <Box
          marginTop={6}
          borderRadius="25px"
          background="#022F59"
          display="flex"
          padding="20px"
          width="100%"
        >
          <Box width="50%">
            <Text color="white" fontSize="25px">
              {t("contact1")}
            </Text>
            <Text color="#6AEFAB" fontSize="25px">
              {t("contact2")}
            </Text>
          </Box>
          <Box width="50%">
            <InputGroup
              size="md"
              height="100%"
              background="white"
              borderRadius="10px"
            >
              <Input
                pr="4.5rem"
                // paddingLeft="65px"
                // variant="filled"
                placeholder=""
                height="100%"
                borderTopRightRadius="10px"
                borderBottomRightRadius="10px"
              />
              <InputRightElement
                width="4.5rem"
                background="#6AEFAB"
                borderTopRightRadius="10px"
                borderBottomRightRadius="10px"
                height="100%"
                paddingX={16}
                position="relative"
              >
                <Button
                  // h="1.75rem"
                  size="sm"
                  background="#6AEFAB" onClick={handleClick} 
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  height="100%"
                >
                  <Text>{t("Subscribe")} </Text>
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </Box>
      </Box> */}
      {/* <Box className="section_bef-footer2" marginTop="350px"></Box> */}
      {storeUrlCopied && (
        <ModalAlert
          isOpen={isCopiedOpen}
          onClose={onCopiedClose}
          title={t("Code Copied")}
          desc={t("Paste This Coupon Code Checkout")}
          linkText={t("Go to the store")}
          btnWidthFull={true}
          icon={checked}
          disableClose={true}
          path={`${baseUrl}/Store/${storeID}`}
          hasDesc={true}
          closeOnOverlayClicked={true}
          setStoreUrlCopied={setStoreUrlCopied}
          storeUrlCopied={storeUrlCopied}
          couponID={couponID}
          couponCode={couponCode}
        />
      )}
      <ModalAlert2
        isOpen={isErrorOpen}
        onClose={onErrorClose}
        title={t("Error")}
        desc={errorMessage}
        btnWidthFull={true}
        icon={checked}
        path={`${baseUrl}/Store/${storeID}`}
        hasDesc={true}
        isError={true}
      />
    </div>
  );
};
