import React, { useState, useEffect } from "react";

import {
  GetCategoryByIdAPI,
  UpdateCategoryAPI,
  AddCategoryAPI,
} from "../../api/CategoryAPI";
import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../utils/General";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { CategoryForm } from "./CategoryForm";
import { validationCategoryForm } from "./validationCategoryForm";

import checked from "../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const Category = () => {
  const activeMenu = activeMenuItem();
  const { id } = useParams();
  let location = useLocation();
  //const [isRestore, setIsRestore] = useState(false);
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [imageObj, setImageObj] = useState({});

  let submitModalTitle = id
    ? "Category is successfully updated."
    : "Category is successfully created.";

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    if (!!id) {
      const submitValues = {
        // ...formikValue,
        name_ar: formikValue.name_ar,
        name_en: formikValue.name_en,
        image: formikValue.image,
        category_id: id,
      };

      UpdateCategoryAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      const submitValues = {
        // ...formikValue,
        name_ar: formikValue.name_ar,
        name_en: formikValue.name_en,
        image: formikValue.image,
      };
      AddCategoryAPI(submitValues)
        .then((res) => {
          setDisableSave(true);
          setErrorMessage("");
          onSubmitOpen();
          if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          console.log("err", err);
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    if (!!id) {
      setErrorMessage("");
      GetCategoryByIdAPI(id)
        .then((res) => {
          setInitFormVal({
            name_ar: res.category.name_ar,
            name_en: res.category.name_en,
            image: res.category.image,
          });
          setGeneralData(res.category);
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else {
      setInitFormVal({
        name_en: "",
        name_ar: "",
        image: "",
      });
    }
  }, []);

  return (
    <div className="Assumptions">
      <>
        <MainBox px={0}>
          {id === undefined ? (
            <MainTitle title="Create Category" bBottom={true} px={7} />
          ) : (
            <MainTitle title="Edit Category" bBottom={true} px={7} />
          )}
          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationCategoryForm}
              validate={(values) => {
                if (disableSave) {
                  setDisableSave(false);
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <CategoryForm
                    onCancelOpen={onCancelOpen}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    disableSave={disableSave}
                    setDisableSave={setDisableSave}
                    dismissSave={dismissSave}
                    setDismissSave={setDismissSave}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                    setImageObj={setImageObj}
                  />

                  <ModalAlert
                    isOpen={isCancelOpen}
                    onClose={onCancelClose}
                    title="Are you sure you want to discard the changes?"
                    icon={infoCircle}
                    hasDesc={false}
                    confirmationModal={true}
                    path={`/Admin/CategoriesHistory`}
                    setDismissSave={() => setDismissSave(true)}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/Admin/CategoriesHistory`}
        />
      </>
    </div>
  );
};
