import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost, AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//Coupons
export const GetCouponsAPI = async (page = 1) => {
  const URL = `/coupons?page=${page}&limit=12`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetCouponsFullAPI = async (page = 1) => {
  const URL = `/coupons`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCouponByIdAPI = async (id) => {
  const URL = `/coupons/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddCouponAPI = async (payload) => {
  const URL = "/coupons";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateCouponAPI = async (payload) => {
  // const URL = `/addCoupon/${id}`;
  const URL = `/coupons`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteCouponByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeactiveActiveCouponByIdAPI = async (id, active = 0) => {
  const URL = `/coupons/${id}?number=${active}`;
  try {
    const response = await AxiosClientPost.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getUsedCoupons = async (page = 1) => {
  const URL = `/usedCoupons?page=${page}`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
