import { AxiosClientPost } from "..";
import { AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//Stores
export const GetStoresAPI = async (page = 1) => {
  const URL = `/stores`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetStoresFullAPI = async () => {
  const URL = `/stores?limit=100000`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetCouponsByStore = async (id) => {
  const URL = `/storeCopouns/${id}`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetAllBanners = async (id) => {
  const URL = `/banners`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const PostAllBanners = async (id) => {
  // fetch("https://restful-booker.herokuapp.com/auth", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       username: "admin",
  //       password: "password123",
  //     }),
  //   })
  const URL = `https://restful-booker.herokuapp.com/auth`;
  try {
    const response = await AxiosClientPost.post(
      URL,
      JSON.stringify({
        username: "admin",
        password: "password123",
      })
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetStoreByIdAPI = async (id) => {
  const URL = `/store/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//ABOUT US
export const GetAboutAPI = async () => {
  const URL = `/abouts`;
  try {
    const response = await AxiosClientGet.get(URL, {
      /*  params: {
        token: Cookies.get("token"),
      }, */
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
