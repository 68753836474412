import React, { useState, useEffect } from "react";

import { GetAboutAPI, UpdateAboutAPI } from "../../api/HomeAPI";

import { Formik, Form } from "formik";
import { Box, useDisclosure } from "@chakra-ui/react";

import { activeMenuItem } from "../../utils/General";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { AboutForm } from "./AboutForm";
import { validationAboutForm } from "./validationAboutForm";

import checked from "../../assets/images/iconsSvg/checked.svg";
import infoCircle from "../../assets/images/iconsSvg/infoCircle.svg";

export const About = () => {
  const activeMenu = activeMenuItem();
  const [initFormVal, setInitFormVal] = useState({});
  const [generalData, setGeneralData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  let submitModalTitle = "About is successfully updated.";

  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleOnSubmit = (formikValue) => {
    const submitValues = {
      id: formikValue.id,
      descEn: formikValue.description_en,
      descAr: formikValue.description_ar,
    };

    UpdateAboutAPI(submitValues)
      .then((res) => {
        setErrorMessage("");
        onSubmitOpen();
        if (res.success === true) {
          setErrorMessage("");
          onSubmitOpen();
        }
        return res;
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message || "Something went wrong");
        onErrorOpen();
      });
  };

  useEffect(() => {
    GetAboutAPI()
      .then((res) => {
        setInitFormVal({
          description_en: res.about.rows[0].description_en,
          description_ar: res.about.rows[0].description_ar,
          id: res.about.rows[0].id,
        });
        setGeneralData(res.about.rows[0]);
      })
      .catch((err) => {
        setErrorMessage("Something went wrong");
        onErrorOpen();
      });
  }, []);

  return (
    <div className="About">
      <>
        <MainBox px={0}>
          <MainTitle title="Edit About" bBottom={true} px={7} />

          <Box className="table_content" pt={6} pb={6}>
            <Formik
              initialValues={initFormVal}
              enableReinitialize={true}
              validationSchema={validationAboutForm}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isValid,
                dirty,
              }) => (
                <Form className="form_wrapper">
                  <AboutForm
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    formikValues={values}
                    isValid={isValid}
                    dirty={dirty}
                    onSubmitOpen={onSubmitOpen}
                    onUploadOpen={onUploadOpen}
                    handleOnSubmit={handleOnSubmit}
                    setInitFormVal={setInitFormVal}
                    generalData={generalData}
                    isUploadOpen={isUploadOpen}
                    onUploadClose={onUploadClose}
                    onErrorOpen={onErrorOpen}
                    setErrorMessage={setErrorMessage}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </MainBox>

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/Admin/About`}
        />
      </>
    </div>
  );
};
