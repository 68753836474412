import axiosClient from "./index";
import { AxiosClientMultipart, AxiosClientPost, AxiosClientGet } from "./index";
import Cookies from "js-cookie";

//BANNERS
export const GetBannersAPI = async (page = 1) => {
  // const URL = `/banners`;
  const URL = `/banners?page=${page}&limit=10`;
  try {
    const response = await AxiosClientGet.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetBannerByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientGet.get(URL, {
      params: {
        token: Cookies.get("token"),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddBannerAPI = async (payload) => {
  const URL = "/banners";
  try {
    const response = await AxiosClientMultipart.post(URL, {
      ...payload,
      //auth: Cookies.get("token"),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateBannerAPI = async (payload) => {
  // const URL = `/addBanner/${id}`;
  const URL = `/editBanner`;
  try {
    const response = await AxiosClientPost.put(URL, {
      ...payload,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteBannerByIdAPI = async (id) => {
  const URL = `/banners/${id}`;
  try {
    const response = await AxiosClientPost.delete(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeactiveActiveBannerByIdAPI = async (id, active = 0) => {
  const URL = `/banners/${id}?number=${active}`;
  try {
    const response = await AxiosClientPost.put(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
